// import { hot } from 'react-hot-loader/root';
import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from '../helpers/history';
import { helpers, general, api, h } from '../helpers/index';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import { setTheme } from '../actions/config';
import { setConstants } from '../actions/constants';
import { setPermission } from '../actions/permission';
import { ToastContainer } from 'react-toastify';
import Spinner from 'react-spinkit';
import classnames from 'classnames';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import '../styles/css/bootstrap.css';
import '../styles/css/emoji-mart.css';
import '../styles/css/layout.css';
import '../styles/css/theme.css';
import '../styles/css/ui.css';
import '../styles/css/app.css';
import '../styles/css/custom.css';
import 'react-table/react-table.css';
import accountsNinjaTheme from '../themes/accountsNinjaTheme';
import lightTheme from '../themes/lightTheme';
import darkTheme from '../themes/darkTheme';
import grayTheme from '../themes/grayTheme';
import * as GeneralModel from '../models/general';
import { config } from '../configs/config';
import ContentAdd from 'material-ui/svg-icons/communication/chat';
import Tooltip from './Partial/UI/Tooltip';

const PrivateRoute = lazy(() => import('../components/Common/CommonPrivateRoute'));
const Common404 = lazy(() => import('../components/Common/Common404'));
const UserLogin = lazy(() => import('../components/User/UserLogin'));
const UserSignup = lazy(() => import('../components/User/UserSignup'));
const UserInviteSignup = lazy(() => import('../components/User/UserInviteSignup'));
const UserEmailVerification = lazy(() => import('../components/User/UserEmailVerification'));
const UserForgotPassword = lazy(() => import('../components/User/UserForgotPassword'));
const UserResetPassword = lazy(() => import('../components/User/UserResetPassword'));
const UserSettings = lazy(() => import('../components/User/UserSettings'));
const DashboardIndex = lazy(() => import('../components/Dashboard/DashboardIndex'));
const VerificationIndex = lazy(() => import('../components/Verification/VerificationIndex'));
const TaskIndex = lazy(() => import('../components/Task/TaskIndex'));
const TaskMessageHistory = lazy(() => import('../components/Task/TaskMessageHistory'));
const EntityIndex = lazy(() => import('../components/Entity/EntityIndex'));
const EntityForm = lazy(() => import('../components/Entity/EntityForm'));
const CaptureIndex = lazy(() => import('../components/Capture/CaptureIndex'));
const EntityUserForm = lazy(() => import('../components/Entity/EntityUserForm'));
const UserManagementIndex = lazy(() => import('../components/UserManagement/UserManagementIndex'));
const UserGroupManagementIndex = lazy(() => import('../components/UserManagement/UserGroupManagementIndex'));
const AdminSettingsIndex = lazy(() => import('../components/AdminSettings/AdminSettingsIndex'));
const Members = lazy(() => import('../components/Members/Members'));
const MemberDetails = lazy(() => import('../components/Members/MemberDetails'));
const QueueJobIndex = lazy(() => import('../components/QueueJob/QueueJobIndex'));
const AccountingDashboardIndex = lazy(() => import('../components/AccountingDashboard/AccountingDashboardIndex'));
const MemberDocumentDetails = lazy(() => import('../components/Members/MemberDocumentDetails'));
const NowInfinityCallback = lazy(() => import('./Auth/NowInfinity/NowInfinityCallback'));
const FormsIndex = lazy(() => import('../components/Forms/FormsIndex'));
const FormsRenderer = lazy(() => import('./Forms/FormsRenderer'));

class Index extends Component {

    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            hasPermission: true,
            isLoggedIn: true,
            constants: {},
            userPermissions: {},
            retrievedPermissions: false
        };
    }

    componentDidMount () {
        var self = this;
        GeneralModel.getConstant({}, function (data) {
            var apiRes = api.handleApiResponse(data, false);
            if (apiRes.status === 'ok' && apiRes.data) {
                // reload system constants
                if (apiRes.data.constants) {
                    self.props.setConstants(apiRes.data.constants);
                }
            }
        });

        //Initialize intercom chat in production environment only
        // if (h.cmpStr(h.general.getEnv(), 'production')) {
        //    h.spChat.initIntercom(config.intercom.appId, () => {});
        // }
    }

    updateLoading (loading) {
        if (this.refs.spinner) {
            this.setState({loading: loading});
        }
    }

    noPermission (perm) {
        if (this.refs.redirect) {
            this.setState({hasPermission: perm});
        }
    }

    isAuthenticated (perm) {
        if (this.refs.isLoggedIn) {
            this.setState({isLoggedIn: perm});
        }
    }

    render () {
        const layoutBoxed = false;
        const navCollapsed = false;
        const navBehind = false;
        const fixedHeader = true;
        const sidebarWidth = 'small';
        // colorOption: '14',                              // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
        const theme = 'accountsNinjaTheme';
        const { setTheme, loaderStatus } = this.props;

        let materialUITheme;
        switch (theme) {
            case 'gray':
                materialUITheme = grayTheme;
                break;
            case 'dark':
                materialUITheme = darkTheme;
                break;
            case 'accountsNinjaTheme':
                materialUITheme = accountsNinjaTheme;
                break;
            default:
                materialUITheme = lightTheme;
        }
        setTheme(materialUITheme);

        // loading indicator container
        var SpinnerContainer = '';

        // loading indicator container
        if (!general.isEmpty(loaderStatus)) {
            //Precedence to show network request loader over redux state loader
            if(this.state.loading) {
                SpinnerContainer =
                    <div className="spinnerWrapper">
                        <Spinner name='folding-cube' color="#0091F4" overrideSpinnerClassName="spinner"/>
                    </div>
            } else {
                SpinnerContainer = general.compareBoolean(loaderStatus, true) ?
                    <div className="spinnerWrapper">
                        <Spinner name='folding-cube' color="#0091F4" overrideSpinnerClassName="spinner"/>
                    </div>
                    : '';
            }

        } else {
            SpinnerContainer = general.compareBoolean(this.state.loading, true) ?
                <div className="spinnerWrapper">
                    <Spinner name='folding-cube' color="#0091F4" overrideSpinnerClassName="spinner"/>
                </div>
                : '';
        }

        // user does not have permission to access feature
        var PermissionRedirect = !this.state.hasPermission && Boolean(this.state.hasPermission) === false ?
            <Redirect to="/dashboard" push={true} /> : '';

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(materialUITheme)}>
                <div id="app-inner">
                    <div className="preloaderbar hide"><span className="bar" /></div>
                    <div
                        className={classnames('app-main full-height', {
                            'fixed-header': fixedHeader,
                            'nav-collapsed': navCollapsed,
                            'nav-behind': navBehind,
                            'layout-boxed': layoutBoxed,
                            'theme-gray': theme === 'gray',
                            'theme-dark': theme === 'dark',
                            'sidebar-sm': sidebarWidth === 'small',
                            'sidebar-lg': sidebarWidth === 'large'})
                        }>
                        {/* loading indicator */}
                        <div ref="spinner">{SpinnerContainer}</div>


                            <Router history={history}>
                                <div>
                                    <div ref="redirect">{PermissionRedirect}</div>
                                    <helpers.api.HttpInterceptors noPermission={this.noPermission.bind(this)}
                                                                  updateLoading={this.updateLoading.bind(this)}
                                                                  isAuthenticated={this.isAuthenticated.bind(this)}
                                                                  {...this.state}/>
                                    <Suspense fallback={<div>Loading...</div>}>
                                    <Tooltip
                                        style={{ position: 'fixed', bottom: 90, right: 30, zIndex: 60000 }}
                                        content={'Need help? Reach out to us.'}
                                    >
                                        <FloatingActionButton 
                                            style={{ position: 'fixed', bottom: 25, right: 30, zIndex: 50000 }}
                                            href="mailto:support@getzave.com?subject=New Support Request via ZaveHub"
                                            backgroundColor={'#438fec'}
                                        >
                                            <ContentAdd />
                                        </FloatingActionButton>
                                    </Tooltip>
                                        <Switch>
                                            <Route exact path='/' component={UserLogin}/>
                                            <Route exact path='/login' component={UserLogin}/>
                                            <Route exact path='/signup' component={UserSignup}/>
                                            <Route exact path='/invite/:token' component={UserInviteSignup}/>
                                            <Route exact path='/verify-email' component={UserEmailVerification}/>
                                            <Route exact path='/verify-email/:token' component={UserEmailVerification}/>
                                            <Route exact path='/sp-forgot-password' component={UserForgotPassword}/>
                                            <Route exact path='/sp-reset-password/:token' component={UserResetPassword}/>
                                            <Route exact path="/forms" component={FormsIndex}/>
                                            <Route exact path="/forms/:form_type/" component={FormsIndex}/>
                                            <Route exact path="/forms/:form_type/:country_code/" component={FormsRenderer}/>
                                            <Route exact path="/forms/:form_type/:country_code/:form_id/" component={FormsRenderer}/>
                                            <Route exact path="/forms/:form_type/:country_code/:form_id/:stage/" component={FormsRenderer}/>

                                            <PrivateRoute exact path="/dashboard" component={DashboardIndex}/>
                                            <PrivateRoute exact path="/user-settings" component={UserSettings}/>
                                            <PrivateRoute exact path="/user-settings/:module" component={UserSettings}/>
                                            <PrivateRoute exact path="/verifications" component={VerificationIndex}/>
                                            <PrivateRoute exact path="/tasks" component={TaskIndex}/>
                                            <PrivateRoute exact path="/tasks/history" component={TaskMessageHistory}/>
                                            <PrivateRoute exact path="/tasks/:taskId" component={TaskIndex}/>
                                            <PrivateRoute exact path="/entities" component={EntityIndex}/>
                                            <PrivateRoute exact path="/entity" component={EntityForm}/>
                                            <PrivateRoute exact path="/entities/:entityId" component={EntityIndex}/>
                                            <PrivateRoute exact path="/entities/:entityId/:module" component={EntityIndex}/>
                                            <PrivateRoute exact path="/entities/:entityId/:module/:action" component={EntityIndex}/>
                                            <PrivateRoute exact path="/entity/user" component={EntityUserForm}/>
                                            <PrivateRoute exact path="/user-management" component={UserManagementIndex}/>
                                            <PrivateRoute exact path="/user-management/:module" component={UserManagementIndex}/>
                                            <PrivateRoute exact path="/user-management/user-group-management" component={UserGroupManagementIndex}/>
                                            <PrivateRoute exact path="/admin-settings" component={AdminSettingsIndex}/>
                                            <PrivateRoute exact path="/admin-settings/:module_id" component={AdminSettingsIndex}/>
                                            <PrivateRoute exact path="/members" component={Members}/>
                                            <PrivateRoute exact path="/member-details/:memberIdentityId" component={MemberDetails}/>
                                            <PrivateRoute exact path="/member-document-details/:memberIdentityDocumentId" component={MemberDocumentDetails}/>
                                            <PrivateRoute exact path="/auth/nowinfinity/callback" component={NowInfinityCallback}/>
                                            <PrivateRoute exact path="/accounting/dashboard" component={AccountingDashboardIndex}/>
                                            <PrivateRoute exact path="/accounting/submissions" component={CaptureIndex}/>
                                            <PrivateRoute exact path="/accounting/queue-jobs" component={QueueJobIndex}/>
                                            <Route component={Common404}/>
                                        </Switch>
                                    </Suspense>
                                </div>
                            </Router>

                        {/* notification container */}
                        <ToastContainer
                            position="top-center"
                            type="default"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            pauseOnHover
                        />
                        
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }

}

Index.propTypes = {
    currentUser: PropTypes.object.isRequired,
    theme: PropTypes.object,
    constants: PropTypes.object.isRequired,
    userPermissions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user,
    theme: state.config.theme,
    loaderStatus: state.config.loaderStatus,
    constants: state.constants,
    userPermissions: state.permission
});

const mapDispatchToProps = {
    setTheme,
    setConstants,
    setPermission
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
