export default function serviceProvider(state = {
    serviceProviderServices: {}
}, action) {
    switch (action.type) {
        case 'SET_CURRENT_SERVICE_PROVIDER_BY_ID':
            return {
                ...state,
                serviceProviders: Object.assign([], action.serviceProviders),
                currentServiceProvider: action.serviceProviders.filter(serviceProvider =>
                    String(serviceProvider.service_provider_id) === String(action.serviceProviderId)
                )[0] || action.serviceProviders[0]
            };
        case 'SET_CURRENT_SERVICE_PROVIDER_USER_SERVICES': {
            let { services = [] } = action;
            let services_formatted = services.reduce((last, cur) => {
                last[cur] = 1;
                return last;
            }, {});
            return {
                ...state,
                serviceProviderServices: Object.assign({}, services_formatted)
            };
        }
        case 'SET_CURRENT_SERVICE_PROVIDER_PERMISSIONS':
            return {
                ...state,
                permissions: action.permissions
            }
        default:
            return state;
    }
}
