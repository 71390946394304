import React from 'react';
// https://github.com/fkhadra/react-toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { default as swal } from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {general} from "./index";
import $ from 'jquery';
import { config } from '../configs/config';

String.prototype.replaceAll = function(searchStr, replaceStr) {
    var str = this;

    // escape regexp special characters in search string
    searchStr = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    return str.replace(new RegExp(searchStr, 'gi'), replaceStr);
};

if (!Object.values) {
    /**
     * Polyfill for Object.values to support IE11
     * @param {object} obj
     * @returns {[]}
     */
    Object.values = function (obj) {
        if (!obj) return [];
        if (typeof obj !== 'object') return [];
        return Object.keys(obj).map(key => {
            return obj[key];
        });
    };
}

if (!Object.entries) {
    /**
     * Polyfill for Object.entries to support IE11
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
     * @param {object} obj
     * @returns {[]}
     */
    Object.entries = function( obj ){
        if (!obj) return [];
        if (typeof obj !== 'object') return [];
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    };
}

/**
 * get current environment based on hostname
 * @returns {*}
 */
export function getEnv () {
    var debug = false,
        hostName = window.location.hostname,
        processEnvironment = function (debug, environment) {
            if (debug) console.log('Current environment: ' + environment);
            return environment;
        };
    // development environment
    if (hostName.indexOf('localhost') > -1) {
        return processEnvironment(debug, 'development');
    }
    // staging environment
    else if (hostName.indexOf('nerdyhawk.com') > -1) {
        return processEnvironment(debug, 'staging');
    }
    // qa environment
    else if (hostName.indexOf('primaryone.net') > -1) {
        return processEnvironment(debug, 'qa');
    }
    // production environment
    else {
        return processEnvironment(debug, 'production');
    }
}

/**
 * checks if environment is development
 * @returns {boolean}
 */
export function isDev () {
    if (compareString(getEnv(), 'development')) {
        return true;
    } else {
        return false;
    }
}

/**
 * gets all url GET parameters
 * @param url
 * @returns {string}
 */
export function findGetParameter (parameterName) {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}
/**
 * Get all hash parameters as an object
 * @returns {*}
 */
export function getHashParameters () {
    let href = window.location.href;
    //Acquire the params from the hash
    let lastChar = null;
    let isAfterHash = false;
    let queryStr = '';
    for(let i = 0; i < href.length; i++) {
        let curChar = href[i];
        if(curChar == '#') {
            isAfterHash = true;
            continue;
        }
        if(isAfterHash) {
            if(lastChar == '#' && curChar == '!') {
                continue; //Ignore hash bang format
            }
            else {
                queryStr += curChar;
            }
        }
        //Update the last char to the current char
        lastChar = curChar;
    }

    //Parse the query str
    let params = queryStr.split('&').map(decl => {
        let tokens = decl.split('=');
        let key = tokens[0];
        let val = decodeURIComponent(tokens[1]);
        return { key, val };
    }).reduce((state, cur) => {
        state[cur.key] = cur.val;
        return state;
    }, {});
    return params;
}

/**
 * initialize state for standard structure across all react elements
 *
 * [SAMPLE additionalStates data]
 * additionalStates: {
 *      fields: [
 *          {
 *              value: 'default value of field',
 *              dirty: 'boolean (true/false) indicator to indicate by default if field is edited or not'
 *              // this is usually used for fields that will not have user interactions on it
 *              // form submission validation will checked if fields have been edited or not using this dirty flag
 *          }
 *          ...
 *      },
 *      ...
 * }
 * @template T
 * @param {T} additionalStates
 * @returns {{ loading: boolean, fields: Object<string, { value: any, error: string, dirty: boolean, changed: boolean}> } & T}
 */
export function initState (additionalStates) {
    // add any states that you want to have across all components here
    var initializedState = {
        loading: false
    };
    if (additionalStates) {
        for (var additionalState in additionalStates) {
            initializedState[additionalState] = additionalStates[additionalState];
            if (additionalState === 'fields') {
                for (var field in initializedState[additionalState]) {
                    initializedState[additionalState][field] = {
                        // store actual value of field
                        value: initializedState[additionalState][field].value || '',
                        // store error message of field
                        error: '',
                        // flag to indicate whether field has been edited before
                        dirty: initializedState[additionalState][field].dirty || false,
                        // flag to indicate whether value has changed compared to previous value
                        changed: false
                    };
                }
            }
        }
    }
    return initializedState;
}

/**
 * generic alert
 * @param message
 * @param type Takes in 'success' or 'error'
 */
let user_current_alert = null;
export function alert (message, type) {
    if(!user_current_alert) {
        setTimeout(() => { //Reset after 5 seconds
            user_current_alert = null;
        }, 5000);
        type = type || 'success';
        toast.success(message, {
            type: type,
            className: 'toast-' + type
        });
        user_current_alert = {message, type};
    }
}

/**
 * generic alert with no timeout limit set
 * @param message
 * @param type Takes in 'success' or 'error'
 */
export function alertNoLimit (message, type) {
    type = type || 'success';
    toast.success(message, {
        type: type,
        className: 'toast-' + type
    });
}
/**
 * @typedef PromptData
 * @property {string} [title]
 * @property {string} message
 */
/**
 * generic prompt using Sweetalert2
 * @param {PromptData} data
 * @param callback
 */
export function prompt (data, callback) {
    if (data && data.message) {
        swal({
            title: data.title || 'Zave',
            html: data.message,
            type: data.alertType || "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#0091F4",
            confirmButtonText: "Yes",
            cancelButtonColor: "#344fb2",
            cancelButtonText: "No"
        }).then(function () {
            callback(true);
        }, function () {
            callback(false);
        });
    }
}

/**
 * generic prompt using Sweetalert2
 * @param {PromptData} data
 * @param callback
 */
export function promptAlert (data, callback) {
    if (data && data.message) {
        swal({
            title: data.title || 'Zave',
            html: data.message,
            type: data.alertType || "warning",
            reverseButtons: true,
            showCancelButton: false,
            confirmButtonColor: "#0091F4",
            confirmButtonText: "Ok"
        }).then(function () {
            callback(true);
        }, function () {
            callback(false);
        });
    }
}

/**
 * capitalize first letter of word
 * @param str
 * @returns {string}
 */
export function ucFirst (str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

/**
 * capitalize all first letters of sentence
 * @param str
 * @returns {string}
 */
export function ucFirstAllWords (str) {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}

/**
 * initalize and load system constants and user permissions (if applicable)
 * @param props
 * @returns {XML}
 */
export function initConfig (props) {
    const { constants, userPermissions } = props;
    if (typeof constants === typeof undefined && typeof userPermissions === typeof undefined) {
        return <div/>
    } else if (Object.keys(constants).length === 0 || Object.keys(userPermissions).length === 0) {
        return <div/>
    }
}

/**
 * check if value exists
 * @param value
 * @returns {boolean}
 */
export function isset (value) {
    if (typeof value !== typeof undefined) {
        return true;
    }
    return false;
}

/**
 * check variable for empty value
 * @param value
 * @returns {boolean}
 */
export function isEmpty (value) {
    // test results
    //---------------
    // []        true, empty array
    // {}        true, empty object
    // null      true
    // undefined true
    // ""        true, empty string
    // ''        true, empty string
    // 0         false, number
    // true      false, boolean
    // false     false, boolean
    // Date      false
    // function  false

    if (value === undefined)
        return true;

    if (typeof (value) === 'function' ||
        typeof (value) === 'number' ||
        typeof (value) === 'boolean' ||
        Object.prototype.toString.call(value) === '[object Date]')
        return false;

    if (value === null || value.length === 0)        // null or 0 length array
        return true;

    if (typeof (value) === "object") {
        // empty object

        var r = true;

        if (Object.keys(value).length > 0) {
            r = false;
        }

        return r;
    }

    return false;
}

/**
 * inverse of isEmpty function
 * @param value
 * @returns {boolean}
 */
export function notEmpty (value) {
    return !isEmpty(value);
}

/**
 * delete key value from object
 * @param obj
 * @param key
 * @returns {*}
 */
export function deleteObjectKey (obj, key) {
    if (this.isset(obj) && this.isset(key)) {
        if (obj.hasOwnProperty(key)) {
            delete obj[key];
        }
    }
    return obj;
}

/**
 * truncate text with ellipsis
 * @param string
 * @returns {*}
 */
export function truncate(string, length, ellipsis){
    length = typeof length !== typeof undefined ? length : 20;
    ellipsis = typeof ellipsis !== typeof undefined ? ellipsis : '...';
    if (string.length > length) {
        return string.substring(0, length) + ellipsis;
    } else {
        return string;
    }
};

/**
 * truncate text array with ellipsis (supports emoji rendering)
 * @param string
 * @param length
 * @param ellipsis
 * @returns {*}
 */
export function truncateArray (string, length, ellipsis) {
    length = typeof length !== typeof undefined ? length : 20;
    ellipsis = typeof ellipsis !== typeof undefined ? ellipsis : '...';
    var actualLength = 0;
    // get length of text
    for (var i = 0; i < string.length; i++) {
        var strPart = string[i];
        // supports emoji rendering
        if (this.isEmpty(strPart.props)) {
            if (!this.isEmpty(strPart[0])) {
                strPart = strPart[0];
                actualLength += strPart.length;
                if (actualLength > length) {
                    string[i][0] = this.truncate(strPart, length, ellipsis);
                    return string;
                }
            }
        } else {
            actualLength++;
            if (actualLength > length) {
                string.push(ellipsis);
                return string;
            }
        }
    }
    return string;
};

/**
 * compare two values and returns true if match else false
 * @param str1
 * @param str2
 */
export function compareString (str1, str2) {
    if (typeof str1 !== typeof undefined && typeof str2 !== typeof undefined) {
        if (String(str1).toLowerCase() === String(str2).toLowerCase()) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareString function
 * @param str1
 * @param str2
 */
export function cmpStr (str1, str2) {
    return this.compareString(str1, str2);
}

/**
 * compare two booleans and returns true if match else false
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function compareBoolean (value1, value2) {
    if (typeof value1 !== typeof undefined && typeof value1 !== typeof undefined) {
        if (Boolean(value1) === Boolean(value2)) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareBoolean function
 * @param value1
 * @param value2
 */
export function cmpBool (value1, value2) {
    return this.compareBoolean(value1, value2);
}

/**
 * compare two integer and returns true if match else false
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function compareInt (value1, value2) {
    if (typeof value1 !== typeof undefined && typeof value1 !== typeof undefined) {
        if (parseInt(value1, 10) === parseInt(value2, 10)) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareString function
 * @param value1
 * @param value2
 */
export function cmpInt (value1, value2) {
    return this.compareInt(value1, value2);
}

/**
 * compare two float and returns true if match else false
 * @param value1
 * @param value2
 * @returns {boolean}
 */
export function compareFLoat (value1, value2) {
    if (typeof value1 !== typeof undefined && typeof value1 !== typeof undefined) {
        if (parseFloat(value1, 10) === parseFloat(value2, 10)) {
            return true;
        }
    }
    return false;
}

/**
 * alias of compareFLoat function
 * @param value1
 * @param value2
 */
export function cmpFloat (value1, value2) {
    return this.compareFLoat(value1, value2);
}

/**
 * calculates the default number of rows to display for react table
 * @param collection
 * @param maxPerPage
 * @returns {*}
 */
export function calculateRowsToDisplay (collection, maxPerPage) {
    collection = collection || [];
    maxPerPage = maxPerPage || 10;
    var emptyCollectionSize = 1;
    if (collection.length === 0) {
        return emptyCollectionSize;
    }
    if (collection.length / maxPerPage > 1) {
        return maxPerPage;
    }
    return collection.length;
}

/**
 * javascript's version of php nl2br
 * @param str
 * @param is_xhtml
 * @returns {string}
 */
export function nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

/**
 * extracts sub domain from hostname
 * @returns {string}
 */
export function getSubdomain () {
    var extractedSubdomain = '';
    var hostName = window.location.hostname;
    if (!general.isEmpty(hostName)) {
        var splitHostName = hostName.split(".");
        var subdomain = splitHostName[0];
        if (!general.compareString(subdomain, "zave") &&
            !general.compareString(subdomain, "getzaving")) {
            extractedSubdomain = subdomain;
            return extractedSubdomain;
        }
    }
    return extractedSubdomain;
}

/**
 * gets GET paramter from url
 * @param self
 * @param key
 * @returns {string}
 */
export function getGetParam (self, key) {
    if (self &&
        self.props &&
        self.props.match &&
        self.props.match.params &&
        self.props.match.params[key]) {
        return self.props.match.params[key];
    } else {
        return '';
    }
}

/**
 * gets current path from url
 * @param self
 * @returns {string}
 */
export function getCurrentPath (self) {
    if (self &&
        self.props &&
        self.props.location &&
        self.props.location.pathname) {
        return self.props.location.pathname;
    } else {
        return '';
    }
}

/**
 * prettify constant value for display
 * @param value
 * @returns {string}
 */
export function prettifyConstant (value) {
    if (this.isEmpty(value)) {
        return  '';
    }
    else if(value === 'completed_kyc') {
        let strArr = value.split('_');
        let completedStr = this.ucFirst(strArr[0]) + ' ' + strArr[1].toUpperCase();
        return completedStr;
    }
    else {
        if(value === 'inactive_strike_off') {
            return 'Inactive - Strike Off'
        }
        if(value === 'inactive_termination_of_service') {
            return 'Inactive - Termination Of Service'
        }
        return this.ucFirstAllWords(value.replaceAll('_', ' '));
    }
}

/**
 * gets file extension based on file name
 * @param fileName
 * @returns {*}
 */
export function getFileExt (fileName) {
    fileName = typeof fileName !== 'undefined' ? fileName : '';
    var fileNameSplits = fileName.split('.');
    if (fileNameSplits.length < 2) {
        return '';
    } else {
        let fileExt = fileNameSplits[fileNameSplits.length - 1];
        return fileExt ? fileExt.toLowerCase() : '';
    }
}

/**
 * gets file extension based on url
 * @param url
 * @returns {*}
 */
export function getFileExtFromUrl (url) {
    if (isEmpty(url)) {
        return '';
    }
    var urlSplits = url.split('/');
    var fileName = urlSplits[urlSplits.length - 1];
    return this.getFileExt(fileName);
}

/**
 * gets file name based on url
 * @param url
 * @returns {*}
 */
export function getFileNameFromUrl (url) {
    if (isEmpty(url)) {
        return '';
    }
    var urlSplits = url.split('/');
    var fileName = urlSplits[urlSplits.length - 1];
    return fileName;
}

/**
 * compare two value, will return 1, -1, 0 if greater, leaser, and equal respectifely
 *
 * @param type integer, string, float, boolean
 * @param a
 * @param b
 * @returns {number}
 */
export function compareTwoValue (type, a, b) {
    switch (type) {
        case 'float':
            a = a.replace(',', '') || 0;
            b = b.replace(',', '') || 0;
            a = parseFloat(a) || 0;
            b = parseFloat(b) || 0;
            break;

        case 'integer':
        default:
            a = parseInt(a) || 0;
            b = parseInt(b) || 0;
            break;

        case 'string':
            // force null and undefined to the bottom
            a = a === null || a === undefined ? -Infinity : a;
            b = b === null || b === undefined ? -Infinity : b;
            // force any string values to lowercase
            a = typeof a === "string" ? a.toLowerCase() : a;
            b = typeof b === "string" ? b.toLowerCase() : b;
            break;
    }
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    } else {
        return 0;
    }
}

export function strTolowerCase(word) {
    return typeof word === "string" ? word.toLowerCase() : word;
}
/**
 * generic log function
 * @param message
 */
export function log (message) {
    console.log('================================================================');
    console.log(message);
    console.log('================================================================');
}

/**
 * fix missing emoji in react-emoji-render
 * @param message
 * @returns {*}
 */
export function fixEmoji (message) {
    message = message.replace(/:man_dancing:/g, '🕺');
    message = message.replace(/:star-struck:/g, '🤩');
    message = message.replace(/:grinning_face_with_star_eyes:/g, '🤩');
    return message;
}

/**
 * print json object to browser
 * @param jsonObject
 * @returns {*}
 */
export function printJsonObject (jsonObject) {
    if (general.notEmpty(jsonObject)) {
        return <div>
            {Object.keys(jsonObject).map(function (key) {
                if (typeof (jsonObject[key]) === "array") {
                    return <div><small>{key}: {general.printJsonArray(jsonObject[key])}</small></div>;
                } else if (typeof (jsonObject[key]) === "object") {
                    return <div><small>{key}: {general.printJsonObject(jsonObject[key])}</small></div>;
                } else {
                    return <div><small>{key}: {jsonObject[key]}</small></div>;
                }
            })}
        </div>;
    }
    return '';
}

/**
 * print json array to browser
 * @param jsonArray
 * @returns {*}
 */
export function printJsonArray (jsonArray) {
    if (general.notEmpty(jsonArray)) {
        return <div>
            {jsonArray.map(function (jsonObject, index) {
                if (typeof (jsonObject) === "array") {
                    return <div><small>{index}: {general.printJsonArray(jsonObject)}</small></div>;
                } else if (typeof (jsonObject) === "object") {
                    return <div><small>{index}: {general.printJsonObject(jsonObject)}</small></div>;
                } else {
                    return <div><small>{index}: {jsonObject}</small></div>;
                }
            })}
        </div>;
    }
    return '';
}

/**
 * prettify country code for display
 * @param value
 * @returns {string}
 */
export function prettifyCountry (value) {
    if (this.isEmpty(value)) {
        return  '';
    } else {
        switch (value) {
            default:
            case '00':
                return 'Unassigned';
            case '01':
                return 'Other';
            case 'SG':
                return 'Singapore';
            case 'MY':
                return 'Malaysia';
            case 'AU':
                return 'Australia';
            case 'HK':
                return 'Hong Kong';
            case 'VG':
                return 'BVI';
        }
    }
}

/**
 * export data to csv
 * https://jsfiddle.net/jossef/m3rrLzk0/
 * @param fileName
 * @param rows
 */
export function exportToCsv (fileName, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

/**
 * format string with pural default 's' based on count value
 * @param str
 * @param count
 * @param pural
 * @returns {*}
 */
export function formatPural (str, count, pural = undefined) {
    if (this.isEmpty(str)) {
        return '';
    }
    count = this.notEmpty(count) ? count : 0;
    pural = pural || 's';
    if (count > 1) {
        str += 's';
    }
    return str;
}

/**
 * trim whitespace from start and end of string
 * @param value
 * @returns {*}
 */
export function trimStr (value) {
    if (value) {
        return value.replace(/^\s+|\s+$/g, "");
    }
    return "";
}

/**
 * Downloads a file from a url
 * @param {string} fileUrl
 */
export function downloadFile(fileUrl) {
    const downloadApiUrl = `${config.apiUrl}/v1.0.0/general/download`;
    const fileName = general.getFileNameFromUrl(fileUrl);
    fetch(downloadApiUrl, {
        method: 'post',
        headers: { 'content-type': "application/json" },
        body: JSON.stringify({ url: fileUrl })
    })
    .then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = decodeURIComponent(fileName);
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
        general.alert(`File downloaded successfully`);
    });
}

export function formatCustomStyle() {
    let style = {
        fontFamily: 'objektiv-mk2, sans-serif'
    };
    return style;
}

export function getScreenWidth () {
    return window.screen.width;
}

/**
 * Polyfill to support IE11 as a replacement for "new Event('resize')" syntax
 * @param {string} eventName
 * @returns {Event}
 */
export function createDomEvent (eventName) {
    let domEvent = null;
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        domEvent = document.createEvent('UIEvents');
        domEvent.initUIEvent(eventName, true, false, window, 0);
    } else {
        domEvent = new Event(eventName);
    }
    return domEvent;
}

/**
 * Sorts an array of objects by an object key
 * @param {Array} inArray
 * @param {String} objectKey
 * @returns {[]}
 */
export function sortArrayOfObjects (inArray, objectKey) {
    if (!inArray || !objectKey || inArray.length === 0 || !inArray[0][objectKey]) return inArray;
    let arrayToSort = inArray.slice(0);
    arrayToSort.sort((a, b) => {
        const x = a[objectKey].toLowerCase();
        const y = b[objectKey].toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    });
    return arrayToSort;
}

/**
 * Count the number of decimal places in a value
 * Source: https://stackoverflow.com/questions/27082377/get-number-of-decimal-places-with-javascript
 * @param value
 * @returns {number|number}
 */
export function countDecimals (value) {
    let text = value.toString()
    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
    }
    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }
    return 0;
}

/**
 * Count the number of zeros in decimal of a value
 * Source: https://stackoverflow.com/questions/31001901/how-to-count-the-number-of-zero-decimals-in-javascript
 * @param value
 * @returns {number}
 */
export function noOfZerosInDecimal (value) {
    const noOfZeros = Math.floor( Math.log10(value) + 1);
    return noOfZeros && noOfZeros < 0 ? Math.abs(noOfZeros) : 0;
}
