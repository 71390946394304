import { helpers, h } from "./index";

var pricing = {

    /**
     * generic function to filter pricing data by pricing type and pricing id
     * @param pricings
     * @param pricingType
     * @param pricingId
     */
    getPricing: function (pricings, pricingType, pricingId) {
        var pricingData = {};
        if (helpers.general.notEmpty(pricings) &&
            helpers.general.notEmpty(pricingType) &&
            helpers.general.notEmpty(pricingId) &&
            helpers.general.notEmpty(pricings[pricingType])) {
            var pricingTypeData = pricings[pricingType];
            if (helpers.general.notEmpty(pricingTypeData)) {
                for (var i = 0; i < pricingTypeData.length; i++) {
                    const pricing = pricingTypeData[i];
                    if (helpers.general.notEmpty(pricing) &&
                        (
                            helpers.general.cmpStr(pricing.pricing_pricing_id, pricingId) ||
                            helpers.general.cmpStr(pricing.pricing_id, pricingId)
                        )) {
                        pricingData = Object.assign({}, pricing);
                    }
                }
            }
        }
        return pricingData;
    },

    /**
     * generic function to filter pricing plan data by pricing plan type
     * @param pricingPlans
     * @param pricingPlanType
     */
    getPricingPlan: function (pricingPlans, pricingPlanType) {
        var pricingPlanData = {};
        if (helpers.general.notEmpty(pricingPlans) &&
            helpers.general.notEmpty(pricingPlanType)) {
            for (var i = 0; i < pricingPlans.length; i++) {
                const pricingPlan = pricingPlans[i];
                if (helpers.general.notEmpty(pricingPlan)) {
                    if (helpers.general.cmpStr(pricingPlan.type, pricingPlanType)) {
                        pricingPlanData = Object.assign({}, pricingPlan);
                    }
                }
            }
        }
        return pricingPlanData;
    },

    /**
     * generic function to filter pricing plan data by pricing plan type
     * @param pricingPlans
     * @param pricingPlanId
     */
    getPricingPlanByPlanId: function (pricingPlans, pricingPlanId) {
        var pricingPlanData = {};
        if (helpers.general.notEmpty(pricingPlans) && helpers.general.notEmpty(pricingPlanId)) {
            for (var i = 0; i < pricingPlans.length; i++) {
                const pricingPlan = pricingPlans[i];
                if (helpers.general.notEmpty(pricingPlan) && helpers.general.notEmpty(pricingPlan)) {
                    if (helpers.general.cmpStr(pricingPlan.pricing_plan_id, pricingPlanId)) {
                        pricingPlanData = Object.assign({}, pricingPlan);
                    }
                }
            }
        }
        return pricingPlanData;
    },

    /**
     * get currency short prefix based on currency code
     * @param currencyCode
     * @returns {string}
     */
    getCurrencyShortPrefix: function (currencyCode) {
        var currencyPrefix = '';
        switch (currencyCode) {
            default:
            case 'SGD':
                currencyPrefix = 'S';
                break;
        }
        return currencyPrefix;
    },

    /**
     * format pricing plan select options
     * @param pricingPlan
     * @returns {*}
     */
    formatPricingPlanSelectOptions: function (pricingPlan) {
        if (helpers.general.notEmpty(pricingPlan) &&
            helpers.general.notEmpty(pricingPlan.name) &&
            helpers.general.notEmpty(pricingPlan.pricing_plan_id)) {
            return { text: pricingPlan.name, value: pricingPlan.pricing_plan_id };
        } else {
            return { text: '', value: '' };
        }
    },

    formatPaymentMethodOptions: function (paymentMethod){
        if (helpers.general.notEmpty(paymentMethod) &&
            helpers.general.notEmpty(paymentMethod.name) &&
            helpers.general.notEmpty(paymentMethod.payment_method_id)) {
            return { text: paymentMethod.name, value: paymentMethod.payment_method_id };
        } else {
            return { text: '', value: '' };
        }
    },

    /**
     * formats amount with prefix
     * @param pricingPlan
     * @param showPrefix
     * @param showSuffix
     */
    formatAmount: function (pricingPlan, showPrefix, showSuffix) {
        var amount = '';
        showPrefix = helpers.general.notEmpty(showPrefix) ? Boolean(showPrefix) : true;
        showSuffix = helpers.general.notEmpty(showSuffix) ? Boolean(showSuffix) : true;
        if (helpers.general.notEmpty(pricingPlan)) {
            if (helpers.general.cmpBool(showPrefix, true) &&
                helpers.general.notEmpty(pricingPlan.amount_prefix)) {
                amount += pricingPlan.amount_prefix;
            }
            if (helpers.general.notEmpty(pricingPlan.amount)) {
                amount += pricingPlan.amount;
            }
        }
        return amount
    },

    /**
     * get amount by pricing id from pricing plan data
     * @param pricingPlans
     * @param pricingPlanId
     * @param returnRaw
     * @returns {number}
     */
    getAmountByPricingPlanId: function (pricingPlans, pricingPlanId, returnRaw) {
        returnRaw = helpers.general.notEmpty(returnRaw) ? true : false;
        var amount = 0;
        if (helpers.general.notEmpty(pricingPlans) && helpers.general.notEmpty(pricingPlanId)) {
            var pricingPlan = pricing.getPricingPlanByPlanId(pricingPlans, pricingPlanId);
            amount = pricingPlan.amount;
        }
        if (helpers.general.cmpBool(returnRaw, false)) {
            amount = pricing.formatAmount(pricingPlan);
        } else {
            amount = pricingPlan.amount;
        }
        return amount;
    },

    /**
     * get pricing plan text from pricing plan data
     * @param pricingPlans
     * @param pricingPlanId
     * @returns {string}
     */
    getPricingPlanText: function (pricingPlans, pricingPlanId) {
        var label = '';
        if (helpers.general.notEmpty(pricingPlans) && helpers.general.notEmpty(pricingPlanId)) {
            var pricingPlan = pricing.getPricingPlanByPlanId(pricingPlans, pricingPlanId);
            label = helpers.general.prettifyConstant(pricingPlan.name);
        }
        return label;
    },

    /**
     * get pricing plan type from pricing plan data
     * @param pricingPlans
     * @param pricingPlanId
     * @returns {string}
     */
    getPricingPlanType: function (pricingPlans, pricingPlanId) {
        var type = '';
        if (helpers.general.notEmpty(pricingPlans) && helpers.general.notEmpty(pricingPlanId)) {
            var pricingPlan = pricing.getPricingPlanByPlanId(pricingPlans, pricingPlanId);
            type = pricingPlan.type;
        }
        return type;
    }



};

export default pricing;