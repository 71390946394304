import { h } from '../helpers/index';
import {config as ZaveCommonConfig} from 'zave_common';
import * as ZaveCommon from 'zave_common';

export default function company (state = {}, action) {
    switch (action.type) {
        case 'SET_COMPANY':
            return {
                ...state,
                company: action.company
            };
        case 'SET_ENTITIES':
            return {
                ...state,
                entities: action.entities
            }
        // case 'SET_ENTITY_USER':
        //     return {
        //         ...state,
        //         entityUser: action.entityUser
        //     }
        case 'SET_CURRENT_ENTITY_BY_ENTITY_ID':
            return {
                ...state,
                entities: Object.assign([], action.entities),
                currentEntity: action.entities.filter(entity =>
                    String(entity.entity_id) === String(action.entityId)
                )[0] || action.entities[0]
            }
        case 'SET_BOOKKEEPERS':
            return {
                ...state,
                bookkeepers: action.bookkeepers
            };
        case 'SET_BANK_ACCOUNTS':
            return {
                ...state,
                bankAccounts: action.accounts
            };
        case 'SET_ENTITY':
            var entity = {};
            action.entity = h.notEmpty(action.entity) ? action.entity : {};

            entity.company_id = action.entity.company_id || entity.company_id || '';
            entity.company_name = action.entity.company_name || entity.company_name || '';
            entity.company_url_name = action.entity.company_url_name || entity.company_url_name || '';
            entity.company_logo = action.entity.company_logo || entity.company_logo || '';
            entity.company_status = action.entity.status || entity.company_status || '';
            entity.company_billing_entity_fk = action.entity.billing_entity_fk || entity.company_billing_entity_fk || '';
            entity.company_billing_entity_name = action.entity.billing_entity_name || entity.company_billing_entity_name || '';

            entity.entity_id = action.entity.entity_id || entity.entity_id || '';
            entity.entity_name = action.entity.entity_name || entity.entity_name || '';
            entity.entity_name_suffix = action.entity.entity_name_suffix || entity.entity_name_suffix || '';
            entity.entity_short_name = action.entity.entity_short_name || entity.entity_short_name || '';
            entity.entity_office_number = action.entity.office_number || entity.entity_office_number || '';
            entity.entity_address_1 = action.entity.address_1 || entity.entity_address_1 || '';
            entity.entity_address_2 = action.entity.address_2 || entity.entity_address_2 || '';
            entity.entity_postal_code = action.entity.postal_code || entity.entity_postal_code || '';
            entity.entity_country_code = action.entity.country_code || entity.entity_country_code || '';
            entity.entity_currency_code = action.entity.entity_currency_code || entity.entity_currency_code || '';
            entity.entity_industry = action.entity.industry || entity.entity_industry || '';
            entity.entity_industry_pretty = action.entity.industry_pretty || entity.entity_industry_pretty || '';
            entity.entity_industry_icon = action.entity.industry_icon || entity.entity_industry_icon || '';
            entity.entity_registration_number = action.entity.registration_number || entity.entity_registration_number || '';
            entity.certificate_of_incorporate_number = action.entity.certificate_of_incorporate_number || entity.certificate_of_incorporate_number || '';
            entity.australian_business_number = action.entity.australian_business_number || entity.australian_business_number || '';
            entity.australian_company_number = action.entity.australian_company_number || entity.australian_company_number || '';
            entity.entity_website_url = action.entity.website_url || entity.entity_website_url || '';
            entity.entity_status = action.entity.entity_status || entity.entity_status || '';
            entity.entity_created_date = action.entity.entity_created_date || entity.entity_created_date || '';
            entity.entity_created_date_raw = action.entity.entity_created_date_raw || entity.entity_created_date_raw || '';
            entity.entity_created_date_seconds = action.entity.entity_created_date_seconds || entity.entity_created_date_seconds || '';
            entity.entity_updated_date = action.entity.entity_updated_date || entity.entity_updated_date || '';
            entity.entity_updated_date_raw = action.entity.entity_updated_date_raw || entity.entity_updated_date_raw || '';
            entity.entity_updated_date_seconds = action.entity.entity_updated_date_seconds || entity.entity_updated_date_seconds || '';
            entity.entity_incorporated = action.entity.incorporated || entity.entity_incorporated || 0;
            entity.entity_name_reserved = action.entity.name_reserved || entity.entity_name_reserved || 0;
            entity.entity_gst_registered = action.entity.gst_registered || entity.entity_gst_registered || 0;
            entity.entity_audit_exempt = action.entity.audit_exempt || entity.entity_audit_exempt || 0;
            entity.entity_eci_exempt = action.entity.eci_exempt || entity.entity_eci_exempt || 0;
            entity.entity_mas_rfmc = action.entity.mas_rfmc || entity.entity_mas_rfmc || 0;
            entity.entity_next_bas = action.entity.next_bas || entity.entity_next_bas || 0;
            
            entity.entity_xbrl_needed = action.entity.xbrl_needed || entity.xbrl_needed || 0;
            entity.ssic_fk_1 = action.entity.ssic_fk_1 || entity.ssic_fk_1 || 0;
            entity.ssic_fk_2 = action.entity.ssic_fk_2 || entity.ssic_fk_2 || 0;
            entity.additional_notes = action.entity.additional_notes || entity.additional_notes || '';
            

            entity.entity_next_bas_days_left = action.entity.next_bas_days_left || entity.entity_next_bas_days_left || 0;
            entity.entity_next_bas_pretty = action.entity.next_bas_pretty || entity.entity_next_bas_pretty || 0;
            entity.entity_next_ias = action.entity.next_ias || entity.entity_next_ias || 0;
            entity.entity_next_ias_days_left = action.entity.next_ias_days_left || entity.entity_next_ias_days_left || 0;
            entity.entity_next_ias_pretty = action.entity.next_ias_pretty || entity.entity_next_ias_pretty || 0;
            entity.entity_next_ar = action.entity.next_ar || entity.entity_next_ar || 0;
            entity.entity_next_ar_days_left = action.entity.next_ar_days_left || entity.entity_next_ar_days_left || 0;
            entity.entity_next_ar_pretty = action.entity.next_ar_pretty || entity.entity_next_ar_pretty || 0;
            
            action.entity.incorporation_date = h.cmpStr(action.entity.incorporation_date, '0000-00-00 00:00:00') ? '' : action.entity.incorporation_date;
            entity.entity_incorporation_date = action.entity.incorporation_date || entity.entity_incorporation_date || '';
            entity.entity_incorporation_date_pretty = action.entity.incorporation_date_pretty || entity.entity_incorporation_date_pretty || '';

            entity.entity_source_of_funds = action.entity.source_of_funds || '';

            action.entity.year_end = h.cmpStr(action.entity.year_end, '0000-00-00') ? '' : action.entity.year_end;
            entity.entity_year_end = action.entity.year_end || entity.entity_year_end || '';
            entity.entity_year_end_pretty = action.entity.year_end_pretty || entity.entity_year_end_pretty || '';

            action.entity.next_agm = h.cmpStr(action.entity.next_agm, '0000-00-00') ? '' : action.entity.next_agm;
            entity.entity_next_agm = action.entity.next_agm || entity.entity_next_agm || '';
            entity.entity_next_agm_pretty = action.entity.next_agm_pretty || entity.entity_next_agm_pretty || '';

            entity.entity_next_tr = action.entity.next_tr || entity.entity_next_tr || '';
            entity.entity_next_tr_pretty = action.entity.next_tr_pretty || entity.entity_next_tr_pretty || '';
            entity.entity_next_eci = action.entity.next_eci || entity.entity_next_eci || '';
            entity.entity_next_eci_pretty = action.entity.next_eci_pretty || entity.entity_next_eci_pretty || '';

            entity.companies_registry_annual_return = action.entity.companies_registry_annual_return || entity.companies_registry_annual_return || '';
            entity.companies_registry_annual_return_pretty = action.entity.companies_registry_annual_return_pretty || entity.companies_registry_annual_return_pretty || '';

            entity.renewal_of_business_registration_date = action.entity.renewal_of_business_registration_date || entity.renewal_of_business_registration_date || '';
            entity.renewal_of_business_registration_date_pretty = action.entity.renewal_of_business_registration_date_pretty || entity.renewal_of_business_registration_date_pretty || '';

            entity.renewal_of_business_registration_due_date = action.entity.renewal_of_business_registration_due_date || entity.renewal_of_business_registration_due_date || '';
            entity.renewal_of_business_registration_due_date_pretty = action.entity.renewal_of_business_registration_due_date_pretty || entity.renewal_of_business_registration_due_date_pretty || '';

            entity.employer_return = action.entity.employer_return || entity.employer_return || '';
            entity.employer_return_pretty = action.entity.employer_return_pretty || entity.employer_return_pretty || '';

            entity.profit_tax_return = action.entity.profit_tax_return || entity.profit_tax_return || '';
            entity.profit_tax_return_pretty = action.entity.profit_tax_return_pretty || entity.profit_tax_return_pretty || '';

            entity.tax_representative_status = action.entity.tax_representative_status || entity.tax_representative_status || '';
            entity.tax_representative_status = h.notEmpty(ZaveCommonConfig.constant.HK_TAX_REP_STATUS) && h.cmpStr(entity.tax_representative_status, ZaveCommonConfig.constant.HK_TAX_REP_STATUSES.CURRENT_SP_REPRESENTED) ? 'Current SP Represented' : entity.tax_representative_status;

            entity.payment_provider_id = action.entity.company_entity_payment_provider_payment_provider_id || entity.payment_provider_id || '';
            entity.payment_provider_status = action.entity.company_entity_payment_provider_status || entity.payment_provider_status || '';
            entity.payment_provider_provider = action.entity.company_entity_payment_provider_provider || entity.payment_provider_provider || '';
            entity.payment_provider_provider_customer_id = action.entity.company_entity_payment_provider_provider_customer_id || entity.payment_provider_provider_customer_id || '';

            entity.is_billing_entity = action.entity.is_billing_entity || entity.is_billing_entity || 0;
            entity.xero_connected = action.entity.xero_connected || entity.xero_connected || 0;
            entity.has_active_payment_method = action.entity.has_active_payment_method || entity.has_active_payment_method || 0;
            entity.has_active_subscription = action.entity.has_active_subscription || entity.has_active_subscription || '';

            if (h.notEmpty(action.entity.email_capture)) {
                entity.email_capture_bills = action.entity.email_capture.bills || entity.email_capture_bills || '';
                entity.email_capture_expenses = action.entity.email_capture.expenses || entity.email_capture_expenses || '';
                entity.email_capture_paidproof = action.entity.email_capture.paidproof || entity.email_capture_paidproof || '';
            } else {
                entity.email_capture_bills = entity.email_capture_bills || '';
                entity.email_capture_expenses = entity.email_capture_expenses || '';
                entity.email_capture_paidproof = entity.email_capture_paidproof || '';
            }

            return {
                ...state,
                entity: entity
            };
        case 'SET_DIRECTORS':
            var directors = [];
            action.directors = h.notEmpty(action.directors) ? action.directors : {};
            for (var i = 0; i < action.directors.length; i++) {
                var director = action.directors[i];
                var directorData = {};
                directorData = setEntityDirector(directorData, director, action.countryCode);
                directors.push(directorData);
            }
            return {
                ...state,
                entityDirectors: directors
            };
        case 'SET_SHAREHOLDERS':
            var shareholders = [];
            action.shareholders = h.notEmpty(action.shareholders) ? action.shareholders : {};
            for (var i = 0; i < action.shareholders.length; i++) {
                var shareholder = action.shareholders[i];
                var shareholderData = {};
                shareholderData = setEntityShareholder(shareholderData, shareholder, action.countryCode);
                shareholders.push(shareholderData);
            }
            return {
                ...state,
                entityShareholders: shareholders
            };
        case 'SET_INCORPORATION_FORM':
            var incorporationForm = {};
            incorporationForm = setIncorporationForm(incorporationForm, action.incorporationForm, action.countryCode || action.incorporationForm.incorporation_form_country_code);
            return {
                ...state,
                incorporationForm: incorporationForm
            };
        case 'SET_INCORPORATION_FORMS':
            var incorporationForms = [];
            for (var i = 0; i < action.incorporationForms.length; i++) {
                var incorporationFormData = action.incorporationForms[i];
                var incorporationForm = {};
                incorporationForm = setIncorporationForm(incorporationForm, incorporationFormData, action.countryCode || incorporationFormData.incorporation_form_country_code);
                incorporationForm = setIncorporationFormUser(incorporationForm, incorporationFormData);
                incorporationForms.push(incorporationForm);
            }
            return {
                ...state,
                incorporationForms: incorporationForms
            };
        case 'SET_DASHBOARD_ENTITIES_DATA':
            return { ...state, dashboardEntitiesData: action.payload }
        case 'SET_DASHBOARD_NEXT_AGMS_DATA':
            return { ...state, dashboardNextAGMData: action.payload }
        case 'SET_DASHBOARD_ANNUAL_TAX_FILING_DATA':
            return { ...state, dashboardAnnualTaxFilingsData: action.payload }
        case 'SET_DASHBOARD_MISSING_ENTITIES_DATA':
            return { ...state, dashboardMissingEntitiesData: action.payload }
        case 'SET_ACCOUNTING_AND_TAX_CLIENTS_DATA':
            return { ...state, dashboardAccountingAndTaxClientsData: action.payload }
        case 'SET_CORPORATE_SECRETARY_CLIENTS_DATA':
            return { ...state, dashboardCorporateSecretaryClientsData: action.payload }
        default:
            return state;
    }
}

/**
 * set incorporation form
 * @param returnData
 * @param incorporationForm
 * @param countryCode
 * @returns {*|{}}
 */
function setIncorporationForm (returnData, incorporationForm, countryCode) {
    returnData = returnData || {};
    returnData.incorporation_form_id = incorporationForm.incorporation_form_incorporation_form_id || returnData.incorporation_form_id;
    returnData.entity_id = incorporationForm.incorporation_form_entity_fk || returnData.entity_id;
    returnData.country_code = incorporationForm.incorporation_form_country_code || returnData.country_code;
    returnData.entity_country_code = returnData.country_code;
    returnData.uri = incorporationForm.incorporation_form_uri || returnData.uri || '';
    returnData.user_email = incorporationForm.incorporation_form_user_email || returnData.user_email || '';
    returnData.user_first_name = incorporationForm.incorporation_form_user_first_name || returnData.user_first_name || '';
    returnData.user_last_name = incorporationForm.incorporation_form_user_last_name || returnData.user_last_name || '';

    switch (countryCode) {
        default:
        case 'SG':
            returnData.entity_name = incorporationForm.incorporation_form_sg_entity_name || returnData.entity_name;
            returnData.incorporation_pricing_plan_id = incorporationForm.incorporation_form_sg_incorporation_pricing_plan_fk || returnData.incorporation_pricing_plan_id;
            returnData.corporate_secretary_pricing_plan_id = incorporationForm.incorporation_form_sg_corporate_secretary_pricing_plan_fk || returnData.corporate_secretary_pricing_plan_id;
            returnData.description = incorporationForm.incorporation_form_sg_description_of_business || returnData.description;
            returnData.industry = incorporationForm.incorporation_form_sg_industry || returnData.industry;
            returnData.financial_year_end = incorporationForm.incorporation_form_sg_financial_year_end || returnData.financial_year_end;
            returnData.financial_year_end_pretty = incorporationForm.incorporation_form_sg_financial_year_end_pretty || returnData.financial_year_end_pretty;
            returnData.financial_year_end_days_left = incorporationForm.incorporation_form_sg_financial_year_end_days_left || returnData.financial_year_end_days_left;
            returnData.use_zave_address = incorporationForm.incorporation_form_sg_is_use_zave_address || returnData.use_zave_address || 0;
            returnData.use_zave_address_pricing_plan_id = incorporationForm.incorporation_form_sg_use_zave_address_pricing_plan_fk || returnData.use_zave_address_pricing_plan_id;
            returnData.address_suite = incorporationForm.incorporation_form_sg_suite || returnData.address_suite;
            returnData.address_street = incorporationForm.incorporation_form_sg_address || returnData.address_street;
            returnData.address_postal = incorporationForm.incorporation_form_sg_postal_code || returnData.address_postal;
            returnData.use_zave_director = incorporationForm.incorporation_form_sg_is_appoint_nominee_director || returnData.use_zave_director || 0;
            returnData.nominee_director_pricing_plan_id = incorporationForm.incorporation_form_sg_nominee_director_pricing_plan_fk || returnData.nominee_director_pricing_plan_id;
            returnData.use_zave_shareholder = incorporationForm.incorporation_form_sg_is_appoint_nominee_shareholder || returnData.use_zave_shareholder || 0;
            returnData.nominee_shareholder_pricing_plan_id = incorporationForm.incorporation_form_sg_nominee_shareholder_pricing_plan_fk || returnData.nominee_shareholder_pricing_plan_id;
            returnData.share_par_value = incorporationForm.incorporation_form_sg_share_par_value || returnData.share_par_value;
            returnData.share_left = incorporationForm.incorporation_form_sg_share_left || returnData.share_left;
            returnData.share_count = incorporationForm.incorporation_form_sg_share_count || returnData.share_count;
            returnData.share_allocated = incorporationForm.share_allocated || 0;
            break;
        case 'MY':
            returnData.entity_name = incorporationForm.incorporation_form_my_entity_name || returnData.entity_name;
            returnData.incorporation_pricing_plan_id = incorporationForm.incorporation_form_my_incorporation_pricing_plan_fk || returnData.incorporation_pricing_plan_id;
            returnData.corporate_secretary_pricing_plan_id = incorporationForm.incorporation_form_my_corporate_secretary_pricing_plan_fk || returnData.corporate_secretary_pricing_plan_id;
            returnData.description = incorporationForm.incorporation_form_my_description_of_business || returnData.description;
            returnData.industry = incorporationForm.incorporation_form_my_industry || returnData.industry;
            returnData.financial_year_end = incorporationForm.incorporation_form_my_financial_year_end || returnData.financial_year_end;
            returnData.financial_year_end_pretty = incorporationForm.incorporation_form_my_financial_year_end_pretty || returnData.financial_year_end_pretty;
            returnData.financial_year_end_days_left = incorporationForm.incorporation_form_my_financial_year_end_days_left || returnData.financial_year_end_days_left;
            returnData.use_zave_address = incorporationForm.incorporation_form_my_is_use_zave_address || returnData.use_zave_address || 0;
            returnData.use_zave_address_pricing_plan_id = incorporationForm.incorporation_form_my_use_zave_address_pricing_plan_fk || returnData.use_zave_address_pricing_plan_id;
            returnData.address_suite = incorporationForm.incorporation_form_my_suite || returnData.address_suite;
            returnData.address_street = incorporationForm.incorporation_form_my_address || returnData.address_street;
            returnData.address_postal = incorporationForm.incorporation_form_my_postal_code || returnData.address_postal;
            returnData.use_zave_director = incorporationForm.incorporation_form_my_is_appoint_nominee_director || returnData.use_zave_director || 0;
            returnData.nominee_director_pricing_plan_id = incorporationForm.incorporation_form_my_nominee_director_pricing_plan_fk || returnData.nominee_director_pricing_plan_id;
            returnData.use_zave_shareholder = incorporationForm.incorporation_form_my_is_appoint_nominee_shareholder || returnData.use_zave_shareholder || 0;
            returnData.nominee_shareholder_pricing_plan_id = incorporationForm.incorporation_form_my_nominee_shareholder_pricing_plan_fk || returnData.nominee_shareholder_pricing_plan_id;
            returnData.share_par_value = incorporationForm.incorporation_form_my_share_par_value || returnData.share_par_value;
            returnData.share_left = incorporationForm.incorporation_form_my_share_left || returnData.share_left;
            returnData.share_count = incorporationForm.incorporation_form_my_share_count || returnData.share_count;
            returnData.share_allocated = incorporationForm.share_allocated || 0;
            break;
        case 'AU':
            returnData.entity_name = incorporationForm.incorporation_form_au_entity_name || returnData.entity_name;
            returnData.incorporation_pricing_plan_id = incorporationForm.incorporation_form_au_incorporation_pricing_plan_fk || returnData.incorporation_pricing_plan_id;
            returnData.corporate_secretary_pricing_plan_id = incorporationForm.incorporation_form_au_corporate_secretary_pricing_plan_fk || returnData.corporate_secretary_pricing_plan_id;
            returnData.description = incorporationForm.incorporation_form_au_description_of_business || returnData.description;
            returnData.industry = incorporationForm.incorporation_form_au_industry || returnData.industry;
            returnData.financial_year_end = incorporationForm.incorporation_form_au_financial_year_end || returnData.financial_year_end;
            returnData.financial_year_end_pretty = incorporationForm.incorporation_form_au_financial_year_end_pretty || returnData.financial_year_end_pretty;
            returnData.financial_year_end_days_left = incorporationForm.incorporation_form_au_financial_year_end_days_left || returnData.financial_year_end_days_left;
            returnData.use_zave_address = incorporationForm.incorporation_form_au_is_use_zave_address || returnData.use_zave_address || 0;
            returnData.use_zave_address_pricing_plan_id = incorporationForm.incorporation_form_au_use_zave_address_pricing_plan_fk || returnData.use_zave_address_pricing_plan_id;
            returnData.address_suite = incorporationForm.incorporation_form_au_suite || returnData.address_suite;
            returnData.address_street = incorporationForm.incorporation_form_au_address || returnData.address_street;
            returnData.address_postal = incorporationForm.incorporation_form_au_postal_code || returnData.address_postal;
            returnData.use_zave_director = incorporationForm.incorporation_form_au_is_appoint_nominee_director || returnData.use_zave_director || 0;
            returnData.nominee_director_pricing_plan_id = incorporationForm.incorporation_form_au_nominee_director_pricing_plan_fk || returnData.nominee_director_pricing_plan_id;
            returnData.use_zave_shareholder = incorporationForm.incorporation_form_au_is_appoint_nominee_shareholder || returnData.use_zave_shareholder || 0;
            returnData.nominee_shareholder_pricing_plan_id = incorporationForm.incorporation_form_au_nominee_shareholder_pricing_plan_fk || returnData.nominee_shareholder_pricing_plan_id;
            returnData.share_par_value = incorporationForm.incorporation_form_au_share_par_value || returnData.share_par_value;
            returnData.share_left = incorporationForm.incorporation_form_au_share_left || returnData.share_left;
            returnData.share_count = incorporationForm.incorporation_form_au_share_count || returnData.share_count;
            returnData.share_allocated = incorporationForm.share_allocated || 0;
            break;
        case 'HK':
            returnData.entity_name = incorporationForm.incorporation_form_hk_entity_name || returnData.entity_name;
            returnData.entity_name_1 = incorporationForm.incorporation_form_hk_entity_name_1 || returnData.entity_name_1;
            returnData.entity_name_2 = incorporationForm.incorporation_form_hk_entity_name_2 || returnData.entity_name_2;
            returnData.incorporation_pricing_plan_id = incorporationForm.incorporation_form_hk_incorporation_pricing_plan_fk || returnData.incorporation_pricing_plan_id;
            returnData.corporate_secretary_pricing_plan_id = incorporationForm.incorporation_form_hk_corporate_secretary_pricing_plan_fk || returnData.corporate_secretary_pricing_plan_id;
            returnData.description = incorporationForm.incorporation_form_hk_description_of_business || returnData.description;
            returnData.industry = incorporationForm.incorporation_form_hk_industry || returnData.industry;
            returnData.financial_year_end = incorporationForm.incorporation_form_hk_financial_year_end || returnData.financial_year_end;
            returnData.financial_year_end_pretty = incorporationForm.incorporation_form_hk_financial_year_end_pretty || returnData.financial_year_end_pretty;
            returnData.financial_year_end_days_left = incorporationForm.incorporation_form_hk_financial_year_end_days_left || returnData.financial_year_end_days_left;
            returnData.use_zave_address = incorporationForm.incorporation_form_hk_is_use_zave_address || returnData.use_zave_address || 0;
            returnData.use_zave_address_pricing_plan_id = incorporationForm.incorporation_form_hk_use_zave_address_pricing_plan_fk || returnData.use_zave_address_pricing_plan_id;
            returnData.address_street = incorporationForm.incorporation_form_hk_address_1 || returnData.address_street;
            returnData.address_suite = incorporationForm.incorporation_form_hk_address_2 || returnData.address_suite;
            returnData.address_1 = incorporationForm.incorporation_form_hk_address_1 || returnData.address_1;
            returnData.address_2 = incorporationForm.incorporation_form_hk_address_2 || returnData.address_2;
            returnData.address_3 = incorporationForm.incorporation_form_hk_address_3 || returnData.address_3;
            returnData.address_4 = incorporationForm.incorporation_form_hk_address_4 || returnData.address_4;
            returnData.address_5 = incorporationForm.incorporation_form_hk_address_5 || returnData.address_5;
            returnData.address_postal = incorporationForm.incorporation_form_hk_postal_code || returnData.address_postal;
            returnData.use_zave_director = incorporationForm.incorporation_form_hk_is_appoint_nominee_director || returnData.use_zave_director || 0;
            returnData.nominee_director_pricing_plan_id = incorporationForm.incorporation_form_hk_nominee_director_pricing_plan_fk || returnData.nominee_director_pricing_plan_id;
            returnData.use_zave_shareholder = incorporationForm.incorporation_form_hk_is_appoint_nominee_shareholder || returnData.use_zave_shareholder || 0;
            returnData.nominee_shareholder_pricing_plan_id = incorporationForm.incorporation_form_hk_nominee_shareholder_pricing_plan_fk || returnData.nominee_shareholder_pricing_plan_id;
            returnData.share_par_value = incorporationForm.incorporation_form_hk_share_par_value || returnData.share_par_value;
            returnData.share_left = incorporationForm.incorporation_form_hk_share_left || returnData.share_left;
            returnData.share_count = incorporationForm.incorporation_form_hk_share_count || returnData.share_count;
            returnData.share_allocated = incorporationForm.share_allocated || 0;
            break;
        case 'VG':
            returnData.entity_name = incorporationForm.incorporation_form_vg_entity_name || returnData.entity_name;
            returnData.incorporation_pricing_plan_id = incorporationForm.incorporation_form_vg_incorporation_pricing_plan_fk || returnData.incorporation_pricing_plan_id;
            returnData.corporate_secretary_pricing_plan_id = incorporationForm.incorporation_form_vg_corporate_secretary_pricing_plan_fk || returnData.corporate_secretary_pricing_plan_id;
            returnData.description = incorporationForm.incorporation_form_vg_description_of_business || returnData.description;
            returnData.industry = incorporationForm.incorporation_form_vg_industry || returnData.industry;
            returnData.financial_year_end = incorporationForm.incorporation_form_vg_financial_year_end || returnData.financial_year_end;
            returnData.financial_year_end_pretty = incorporationForm.incorporation_form_vg_financial_year_end_pretty || returnData.financial_year_end_pretty;
            returnData.financial_year_end_days_left = incorporationForm.incorporation_form_vg_financial_year_end_days_left || returnData.financial_year_end_days_left;
            returnData.use_zave_address = incorporationForm.incorporation_form_vg_is_use_zave_address || returnData.use_zave_address || 0;
            returnData.use_zave_address_pricing_plan_id = incorporationForm.incorporation_form_vg_use_zave_address_pricing_plan_fk || returnData.use_zave_address_pricing_plan_id;
            returnData.address_suite = incorporationForm.incorporation_form_vg_suite || returnData.address_suite;
            returnData.address_street = incorporationForm.incorporation_form_vg_address || returnData.address_street;
            returnData.address_postal = incorporationForm.incorporation_form_vg_postal_code || returnData.address_postal;
            returnData.use_zave_director = incorporationForm.incorporation_form_vg_is_appoint_nominee_director || returnData.use_zave_director || 0;
            returnData.nominee_director_pricing_plan_id = incorporationForm.incorporation_form_vg_nominee_director_pricing_plan_fk || returnData.nominee_director_pricing_plan_id;
            returnData.use_zave_shareholder = incorporationForm.incorporation_form_vg_is_appoint_nominee_shareholder || returnData.use_zave_shareholder || 0;
            returnData.nominee_shareholder_pricing_plan_id = incorporationForm.incorporation_form_vg_nominee_shareholder_pricing_plan_fk || returnData.nominee_shareholder_pricing_plan_id;
            returnData.share_par_value = incorporationForm.incorporation_form_vg_share_par_value || returnData.share_par_value;
            returnData.share_left = incorporationForm.incorporation_form_vg_share_left || returnData.share_left;
            returnData.share_count = incorporationForm.incorporation_form_vg_share_count || returnData.share_count;
            returnData.share_allocated = incorporationForm.share_allocated || 0;
            break;
    }

    returnData.status = incorporationForm.incorporation_form_status || returnData.status || '';
    returnData.created_date = incorporationForm.incorporation_form_created_date || returnData.created_date || '';
    returnData.created_date_raw = incorporationForm.incorporation_form_created_date_raw || returnData.created_date_raw || '';
    returnData.created_date_seconds = incorporationForm.incorporation_form_created_date_seconds || returnData.created_date_seconds || '';
    // incorporation form tracking data
    returnData.name_search_status = incorporationForm.incorporation_form_tracking_name_search_status || returnData.name_search_status || '';
    returnData.particulars_status = incorporationForm.incorporation_form_tracking_particulars_status || returnData.particulars_status || '';
    returnData.directors_status = incorporationForm.incorporation_form_tracking_directors_status || returnData.directors_status || '';
    returnData.shareholders_status = incorporationForm.incorporation_form_tracking_shareholders_status || returnData.shareholders_status || '';
    returnData.user_account_status = incorporationForm.incorporation_form_tracking_user_account_status || returnData.user_account_status || '';
    returnData.check_out_status = incorporationForm.incorporation_form_tracking_check_out_status || returnData.check_out_status || '';
    // incorporation form entity name searches
    returnData.name_searches = incorporationForm.name_searches || [];
    if (h.notEmpty(returnData.name_searches)) {
        for (var i = 0; i < returnData.name_searches.length; i++) {
            const nameSearch = returnData.name_searches[i];
            returnData.name_searches[i].search_id = nameSearch.incorporation_form_name_search_search_id || nameSearch.search_id;
            returnData.name_searches[i].search = nameSearch.incorporation_form_name_search_search || nameSearch.search;
            returnData.name_searches[i].identical_count = !h.isEmpty(nameSearch.incorporation_form_name_search_identical_count) ? nameSearch.incorporation_form_name_search_identical_count : nameSearch.identical_count;
            returnData.name_searches[i].similar_count = !h.isEmpty(nameSearch.incorporation_form_name_search_similar_count) ? nameSearch.incorporation_form_name_search_similar_count : nameSearch.similar_count;
            returnData.name_searches[i].created_date = nameSearch.incorporation_form_name_search_created_date || nameSearch.created_date;
            returnData.name_searches[i].created_date_raw = nameSearch.incorporation_form_name_search_created_date_raw || nameSearch.created_date_raw;
            returnData.name_searches[i].created_date_seconds = nameSearch.incorporation_form_name_search_created_date_seconds || nameSearch.created_date_seconds;
        }
    }

    switch (countryCode) {
        default:
        case 'SG':
            if (h.notEmpty(incorporationForm.incorporation_form_sg_is_use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.incorporation_form_sg_is_use_corporate_secretary;
            } else if (h.notEmpty(incorporationForm.use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.use_corporate_secretary;
            } else {
                returnData.use_corporate_secretary = 1;
            }
            break;
        case 'MY':
            if (h.notEmpty(incorporationForm.incorporation_form_my_is_use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.incorporation_form_my_is_use_corporate_secretary;
            } else if (h.notEmpty(incorporationForm.use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.use_corporate_secretary;
            } else {
                returnData.use_corporate_secretary = 1;
            }
            break;
        case 'AU':
            if (h.notEmpty(incorporationForm.incorporation_form_au_is_use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.incorporation_form_au_is_use_corporate_secretary;
            } else if (h.notEmpty(incorporationForm.use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.use_corporate_secretary;
            } else {
                returnData.use_corporate_secretary = 1;
            }
            break;
        case 'HK':
            if (h.notEmpty(incorporationForm.incorporation_form_hk_is_use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.incorporation_form_hk_is_use_corporate_secretary;
            } else if (h.notEmpty(incorporationForm.use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.use_corporate_secretary;
            } else {
                returnData.use_corporate_secretary = 1;
            }
            break;
        case 'VG':
            if (h.notEmpty(incorporationForm.incorporation_form_vg_is_use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.incorporation_form_vg_is_use_corporate_secretary;
            } else if (h.notEmpty(incorporationForm.use_corporate_secretary)) {
                returnData.use_corporate_secretary = incorporationForm.use_corporate_secretary;
            } else {
                returnData.use_corporate_secretary = 1;
            }
            break;
    }

    return returnData;
}

/**
 * set incorporation form user
 * @param returnData
 * @param incorporationFormUser
 */
function setIncorporationFormUser (returnData, incorporationFormUser) {
    returnData = returnData || {};
    returnData.incorporation_form_user_id = incorporationFormUser.incorporation_form_user_incorporation_form_user_id || returnData.incorporation_form_user_id;
    returnData.auth_type = incorporationFormUser.incorporation_form_user_auth_type || returnData.auth_type;
    returnData.first_name = incorporationFormUser.incorporation_form_user_first_name || returnData.first_name;
    returnData.last_name = incorporationFormUser.incorporation_form_user_last_name || returnData.last_name;
    returnData.mobile_number = incorporationFormUser.incorporation_form_user_mobile_number || returnData.mobile_number;
    returnData.email = incorporationFormUser.incorporation_form_user_email || returnData.email;
    returnData.auth_data = incorporationFormUser.incorporation_form_user_google_auth_data || returnData.auth_data;
    returnData.is_google = incorporationFormUser.incorporation_form_user_email_is_google || returnData.is_google || 0;
    returnData.is_zave_user = incorporationFormUser.incorporation_form_user_is_zave_user || returnData.is_zave_user || 0;
    returnData.is_password_set = incorporationFormUser.incorporation_form_user_is_password_set || returnData.is_password_set || 0;
    return returnData;
}

/**
 * set entity director
 * @param returnData
 * @param director
 * @param countryCode
 * @returns {*}
 */
function setEntityDirector (returnData, director, countryCode) {
    returnData = returnData || {};
    switch (countryCode) {
        default:
        case 'SG':
            returnData.director_id = director.company_entity_director_sg_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_sg_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_sg_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_sg_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_sg_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_sg_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_sg_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_sg_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_sg_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_sg_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_sg_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_sg_date_of_birth = h.cmpStr(director.company_entity_director_sg_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_sg_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_sg_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_sg_country_of_birth || returnData.country_of_birth || '';
            returnData.country_of_birth_pretty = director.company_entity_director_sg_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
            returnData.citizenship_status = director.company_entity_director_sg_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_sg_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_sg_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_sg_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_sg_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_sg_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_sg_hashed_url || returnData.company_entity_director_sg_hashed_url || '';
            returnData.status = director.company_entity_director_sg_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_sg_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_sg_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_sg_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_sg_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_sg_is_nominee || returnData.is_nominee || 0;
            break;
        case 'MY':
            returnData.director_id = director.company_entity_director_my_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_my_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_my_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_my_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_my_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_my_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_my_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_my_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_my_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_my_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_my_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_my_date_of_birth = h.cmpStr(director.company_entity_director_my_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_my_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_my_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_my_country_of_birth || returnData.country_of_birth || '';
            returnData.country_of_birth_pretty = director.company_entity_director_my_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
            returnData.citizenship_status = director.company_entity_director_my_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_my_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_my_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_my_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_my_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_my_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_my_hashed_url || returnData.company_entity_director_my_hashed_url || '';
            returnData.status = director.company_entity_director_my_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_my_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_my_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_my_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_my_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_my_is_nominee || returnData.is_nominee || 0;
            break;
        case 'AU':
            returnData.director_id = director.company_entity_director_au_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_au_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_au_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_au_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_au_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_au_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_au_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_au_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_au_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_au_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_au_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_au_date_of_birth = h.cmpStr(director.company_entity_director_au_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_au_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_au_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_au_country_of_birth || returnData.country_of_birth || '';
            returnData.country_of_birth_pretty = director.company_entity_director_au_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
            returnData.citizenship_status = director.company_entity_director_au_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_au_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_au_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_au_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_au_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_au_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_au_hashed_url || returnData.company_entity_director_au_hashed_url || '';
            returnData.status = director.company_entity_director_au_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_au_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_au_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_au_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_au_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_au_is_nominee || returnData.is_nominee || 0;
            break;
        case 'HK':
            returnData.director_id = director.company_entity_director_hk_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_hk_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_hk_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_hk_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_hk_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_hk_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_hk_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_hk_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_hk_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_hk_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_hk_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_hk_date_of_birth = h.cmpStr(director.company_entity_director_hk_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_hk_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_hk_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_hk_country_of_birth || returnData.country_of_birth || '';
            returnData.country_of_birth_pretty = director.company_entity_director_hk_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
            returnData.citizenship_status = director.company_entity_director_hk_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_hk_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_hk_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_hk_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_hk_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_hk_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_hk_hashed_url || returnData.company_entity_director_hk_hashed_url || '';
            returnData.status = director.company_entity_director_hk_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_hk_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_hk_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_hk_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_hk_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_hk_is_nominee || returnData.is_nominee || 0;
            break;
        case 'VG':
            returnData.director_id = director.company_entity_director_vg_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_vg_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_vg_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_vg_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_vg_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_vg_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_vg_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_vg_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_vg_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_vg_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_vg_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_vg_date_of_birth = h.cmpStr(director.company_entity_director_vg_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_vg_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_vg_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_vg_country_of_birth || returnData.country_of_birth || '';
            returnData.citizenship_status = director.company_entity_director_vg_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_vg_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_vg_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_vg_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_vg_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_vg_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_vg_hashed_url || returnData.company_entity_director_vg_hashed_url || '';
            returnData.status = director.company_entity_director_vg_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_vg_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_vg_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_vg_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_vg_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_vg_is_nominee || returnData.is_nominee || 0;
            break;
        case '00':
            returnData.director_id = director.company_entity_director_00_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_00_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_00_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_00_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_00_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_00_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_00_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_00_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_00_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_00_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_00_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_00_date_of_birth = h.cmpStr(director.company_entity_director_00_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_00_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_00_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_00_country_of_birth || returnData.country_of_birth || '';
            returnData.country_of_birth_pretty = director.company_entity_director_00_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
            returnData.citizenship_status = director.company_entity_director_00_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_00_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_00_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_00_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_00_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_00_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_00_hashed_url || returnData.company_entity_director_00_hashed_url || '';
            returnData.status = director.company_entity_director_00_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_00_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_00_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_00_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_00_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_00_is_nominee || returnData.is_nominee || 0;
            break;
        case '01':
            returnData.director_id = director.company_entity_director_01_director_id || returnData.director_id || '';
            returnData.first_name = director.company_entity_director_01_first_name || returnData.first_name || '';
            returnData.middle_name = director.company_entity_director_01_middle_name || returnData.middle_name || '';
            returnData.last_name = director.company_entity_director_01_last_name || returnData.last_name || '';
            returnData.nationality = director.company_entity_director_01_nationality || returnData.nationality || '';
            returnData.nationality_pretty = director.company_entity_director_01_nationality_pretty || returnData.nationality_pretty || '';
            returnData.email = director.company_entity_director_01_email || returnData.email || '';
            returnData.contact_number = director.company_entity_director_01_contact_number || returnData.contact_number || '';
            returnData.address_line_1 = director.company_entity_director_01_address_line_1 || returnData.address_line_1 || '';
            returnData.address_line_2 = director.company_entity_director_01_address_line_2 || returnData.address_line_2 || '';
            returnData.address_postal_code = director.company_entity_director_01_address_postal_code || returnData.address_postal_code || '';
            director.company_entity_director_01_date_of_birth = h.cmpStr(director.company_entity_director_01_date_of_birth, '0000-00-00') ? '' : director.company_entity_director_01_date_of_birth;
            returnData.date_of_birth = director.company_entity_director_01_date_of_birth || returnData.date_of_birth || '';
            returnData.country_of_birth = director.company_entity_director_01_country_of_birth || returnData.country_of_birth || '';
            returnData.country_of_birth_pretty = director.company_entity_director_01_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
            returnData.citizenship_status = director.company_entity_director_01_citizenship_status || returnData.citizenship_status || '';
            returnData.passport_photo_page = director.company_entity_director_01_passport_photo_page || returnData.passport_photo_page || '';
            returnData.proof_of_address = director.company_entity_director_01_proof_of_address || returnData.proof_of_address || '';
            returnData.nric_front = director.company_entity_director_01_nric_front || returnData.nric_front || '';
            returnData.nric_back = director.company_entity_director_01_nric_back || returnData.nric_back || '';
            returnData.identification_number = director.company_entity_director_01_identification_number || returnData.identification_number || '';
            returnData.hashed_url = director.company_entity_director_01_hashed_url || returnData.company_entity_director_01_hashed_url || '';
            returnData.status = director.company_entity_director_01_status || returnData.status || '';
            returnData.appointment_status = director.company_entity_director_01_appointment_status || returnData.appointment_status || '';
            returnData.appointment_date = director.company_entity_director_01_appointment_date || returnData.appointment_date || '';
            returnData.resignation_date = director.company_entity_director_01_resignation_date || returnData.resignation_date || '';
            returnData.director_remuneration = director.company_entity_director_01_director_remuneration || returnData.director_remuneration || '';
            returnData.is_nominee = director.company_entity_director_01_is_nominee || returnData.is_nominee || 0;
            break;
    }
    return returnData;
}

/**
 * set entity shareholder
 * @param returnData
 * @param shareholder
 * @param countryCode
 * @returns {*}
 */
function setEntityShareholder (returnData, shareholder, countryCode) {
    // generic shareholder information
    returnData = returnData || {};
    switch (countryCode) {
        default:
        case 'SG':
            returnData.shareholder_id = shareholder.company_entity_shareholder_sg_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_sg_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_sg_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_sg_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_sg_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_sg_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_sg_hashed_url || returnData.company_entity_shareholder_sg_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_sg_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_sg_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_sg_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_sg_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_sg_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_sg_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_sg_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_sg_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_sg_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_sg_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_sg_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_sg_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_sg_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_sg_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_sg_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_sg_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_sg_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_sg_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_sg_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_sg_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_sg_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_sg_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_sg_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_sg_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_sg_seniority || returnData.seniority || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_sg_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_sg_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_sg_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_sg_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_sg_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_sg_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_sg_address_postal_code || returnData.address_postal_code || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_sg_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_sg_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_sg_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_sg_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_sg_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_sg_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_sg_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_sg_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_sg_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    returnData.percentage_max_shareholder_list = shareholder.percentage_max_shareholder_list || returnData.percentage_max_shareholder_list || '';
                    
                    break;
                }
            }
            break;
        case 'MY':
            returnData.shareholder_id = shareholder.company_entity_shareholder_my_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_my_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_my_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_my_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_my_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_my_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_my_hashed_url || returnData.company_entity_shareholder_my_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_my_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_my_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_my_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_my_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_my_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_my_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_my_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_my_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_my_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_my_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_my_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_my_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_my_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_my_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_my_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_my_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_my_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_my_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_my_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_my_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_my_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_my_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_my_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_my_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_my_seniority || returnData.seniority || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_my_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_my_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_my_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_my_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_my_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_my_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_my_address_postal_code || returnData.address_postal_code || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_my_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_my_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_my_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_my_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_my_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_my_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_my_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_my_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_my_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    break;
                }
            }
            break;
        case 'AU':
            returnData.shareholder_id = shareholder.company_entity_shareholder_au_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_au_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_au_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_au_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_au_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_au_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_au_hashed_url || returnData.company_entity_shareholder_au_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_au_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_au_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_au_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_au_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_au_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_au_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_au_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_au_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_au_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_au_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_au_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_au_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_au_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_au_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_au_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_au_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_au_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_au_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_au_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_au_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_au_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_au_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_au_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_au_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_au_seniority || returnData.seniority || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_au_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_au_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_au_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_au_address_postal_code || returnData.address_postal_code || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_au_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_au_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_au_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_au_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_au_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_au_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_au_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_au_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_au_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_au_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_au_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_au_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    break;
                }
            }
            break;
        case 'HK':
            returnData.shareholder_id = shareholder.company_entity_shareholder_hk_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_hk_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_hk_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_hk_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_hk_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_hk_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_hk_hashed_url || returnData.company_entity_shareholder_hk_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_hk_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_hk_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_hk_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_hk_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_hk_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_hk_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_hk_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_hk_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_hk_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_hk_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_hk_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_hk_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_hk_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_hk_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_hk_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_hk_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_hk_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_hk_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_hk_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_hk_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_hk_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_hk_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_hk_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_hk_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_hk_seniority || returnData.seniority || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_hk_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_hk_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_hk_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_hk_address_postal_code || returnData.address_postal_code || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_hk_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_hk_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_hk_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_hk_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_hk_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_hk_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_hk_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_hk_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_hk_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_hk_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_hk_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_hk_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    break;
                }
            }
            break;
        case 'VG':
            returnData.shareholder_id = shareholder.company_entity_shareholder_vg_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_vg_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_vg_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_vg_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_vg_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_vg_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_vg_hashed_url || returnData.company_entity_shareholder_vg_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_vg_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_vg_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_vg_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_vg_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_vg_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_vg_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_vg_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_vg_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_vg_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_vg_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_vg_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_vg_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_vg_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_vg_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_vg_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_vg_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_vg_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_vg_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_vg_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_vg_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_vg_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_vg_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_vg_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_vg_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_vg_seniority || returnData.seniority || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_vg_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_vg_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_vg_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_vg_address_postal_code || returnData.address_postal_code || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_vg_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_vg_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_vg_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_vg_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_vg_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_vg_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_vg_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_vg_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_vg_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_vg_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_vg_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_vg_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    break;
                }
            }
            break;
        case '00':
            returnData.shareholder_id = shareholder.company_entity_shareholder_00_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_00_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_00_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_00_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_00_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_00_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_00_hashed_url || returnData.company_entity_shareholder_00_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_00_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_00_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_00_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_00_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_00_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_00_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_00_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_00_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_00_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_00_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_00_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_00_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_00_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_00_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_00_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_00_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_00_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_00_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_00_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_00_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_00_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_00_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_00_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_00_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_00_seniority || returnData.seniority || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_00_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_00_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_00_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_00_address_postal_code || returnData.address_postal_code || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_00_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_00_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_00_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_00_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_00_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_00_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_00_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_00_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_00_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_00_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_00_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_00_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    break;
                }
            }
            break;
        case '01':
            returnData.shareholder_id = shareholder.company_entity_shareholder_01_shareholder_id || returnData.shareholder_id || '';
            returnData.share_count = shareholder.company_entity_shareholder_01_share_count || returnData.share_count || '';
            returnData.paid_up_capital = shareholder.company_entity_shareholder_01_paid_up_capital || returnData.paid_up_capital || '';
            returnData.type = shareholder.company_entity_shareholder_01_type || returnData.type || '';
            returnData.status = shareholder.company_entity_shareholder_01_status || returnData.status || '';
            returnData.is_nominee = shareholder.company_entity_shareholder_01_is_nominee || returnData.is_nominee || 0;
            returnData.hashed_url = shareholder.company_entity_shareholder_01_hashed_url || returnData.company_entity_shareholder_01_hashed_url || '';
            switch(returnData.type) {
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.INDIVIDUAL: {
                    // individual shareholder information
                    returnData.security_type = shareholder.company_entity_shareholder_individual_01_security_type || returnData.security_type || '';
                    returnData.first_name = shareholder.company_entity_shareholder_individual_01_first_name || returnData.first_name || '';
                    returnData.middle_name = shareholder.company_entity_shareholder_individual_01_middle_name || returnData.middle_name || '';
                    returnData.last_name = shareholder.company_entity_shareholder_individual_01_last_name || returnData.last_name || '';
                    returnData.nationality = shareholder.company_entity_shareholder_individual_01_nationality || returnData.nationality || '';
                    returnData.nationality_pretty = shareholder.company_entity_shareholder_individual_01_nationality_pretty || returnData.nationality_pretty || '';
                    returnData.email = shareholder.company_entity_shareholder_individual_01_email || returnData.email || '';
                    returnData.contact_number = shareholder.company_entity_shareholder_individual_01_contact_number || returnData.contact_number || '';
                    shareholder.company_entity_shareholder_individual_01_date_of_birth = h.cmpStr(shareholder.company_entity_shareholder_individual_01_date_of_birth, '0000-00-00') ? '' : shareholder.company_entity_shareholder_individual_01_date_of_birth;
                    returnData.date_of_birth = shareholder.company_entity_shareholder_individual_01_date_of_birth || returnData.date_of_birth || '';
                    returnData.country_of_birth = shareholder.company_entity_shareholder_individual_01_country_of_birth || returnData.country_of_birth || '';
                    returnData.country_of_birth_pretty = shareholder.company_entity_shareholder_individual_01_country_of_birth_pretty || returnData.country_of_birth_pretty || '';
                    returnData.citizenship_status = shareholder.company_entity_shareholder_individual_01_citizenship_status || returnData.citizenship_status || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_individual_01_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_individual_01_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_individual_01_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_individual_01_address_postal_code || returnData.address_postal_code || '';
                    returnData.passport_photo_page = shareholder.company_entity_shareholder_individual_01_passport_photo_page || returnData.passport_photo_page || '';
                    returnData.proof_of_address = shareholder.company_entity_shareholder_individual_01_proof_of_address || returnData.proof_of_address || '';
                    returnData.nric_front = shareholder.company_entity_shareholder_individual_01_nric_front || returnData.nric_front || '';
                    returnData.nric_back = shareholder.company_entity_shareholder_individual_01_nric_back || returnData.nric_back || '';
                    returnData.identification_number = shareholder.company_entity_shareholder_individual_01_identification_number || returnData.identification_number || '';
                    break;
                }
                case ZaveCommon.constants.Entity.SHAREHOLDER_TYPES.CORPORATE: {
                    // corporate shareholder information
                    returnData.seniority = shareholder.company_entity_shareholder_corporate_01_seniority || returnData.seniority || '';
                    returnData.holding_status = shareholder.company_entity_shareholder_corporate_01_holding_status || returnData.holding_status || '';
                    returnData.address_line_1 = shareholder.company_entity_shareholder_corporate_01_address_line_1 || returnData.address_line_1 || '';
                    returnData.address_line_2 = shareholder.company_entity_shareholder_corporate_01_address_line_2 || returnData.address_line_2 || '';
                    returnData.address_postal_code = shareholder.company_entity_shareholder_corporate_01_address_postal_code || returnData.address_postal_code || '';
                    returnData.company_name = shareholder.company_entity_shareholder_corporate_01_company_name || returnData.contact_number || '';
                    returnData.country_code = shareholder.company_entity_shareholder_corporate_01_country_code || returnData.country_code || '';
                    returnData.country_code_pretty = shareholder.company_entity_shareholder_corporate_01_country_code_pretty || returnData.country_code_pretty || '';
                    returnData.auth_rep_first_name = shareholder.company_entity_shareholder_corporate_01_auth_rep_first_name || returnData.auth_rep_first_name || '';
                    returnData.auth_rep_last_name = shareholder.company_entity_shareholder_corporate_01_auth_rep_last_name || returnData.auth_rep_last_name || '';
                    returnData.auth_rep_email = shareholder.company_entity_shareholder_corporate_01_auth_rep_email || returnData.auth_rep_email || '';
                    returnData.auth_rep_identification_number = shareholder.company_entity_shareholder_corporate_01_auth_rep_identification_number || returnData.auth_rep_identification_number || '';
                    returnData.acra_bizfile = shareholder.company_entity_shareholder_corporate_01_acra_bizfile || returnData.acra_bizfile || '';
                    returnData.company_constitution = shareholder.company_entity_shareholder_corporate_01_company_constitution || returnData.company_constitution || '';
                    returnData.certificate_of_incorporation = shareholder.company_entity_shareholder_corporate_01_certificate_of_incorporation || returnData.certificate_of_incorporation || '';
                    returnData.certificate_of_incumbency = shareholder.company_entity_shareholder_corporate_01_certificate_of_incumbency || returnData.certificate_of_incumbency || '';
                    returnData.company_constitution_bylaws = shareholder.company_entity_shareholder_corporate_01_company_constitution_bylaws || returnData.company_constitution_bylaws || '';
                    break;
                }
            }
            break;
    }
    return returnData;
}
