export const setConfig = (config) => ({
    type: 'SET_CONFIG',
    config: config
});

export const setSubdomain = (subdomain) => ({
    type: 'SET_SUBDOMAIN',
    subdomain: subdomain
});

export const setTheme = (theme) => ({
    type: 'SET_THEME',
    theme: theme
});

export const startLoader = () => ({
    type: 'START_LOADER',
    loaderStatus: true
});

export const stopLoader = () => ({
    type: 'STOP_LOADER',
    loaderStatus: false
});

export const setBreadcrumb = (breadcrumbs) => ({
    type: 'SET_BREADCRUMBS',
    breadcrumbs: breadcrumbs
});

export const setCountryCode = (countryCode) => ({
    type: 'SET_CURRENT_COUNTRY_CODE',
    countryCode: countryCode
});