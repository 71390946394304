import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { h } from '../../../helpers';

export class PartialUITooltip extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			hover: false
		};
	}

	render () {
		let { children } = this.props;
		let { hover } = this.state;
		return (
			<span
				style={{
					cursor: 'pointer',
					position: 'relative'
				}}
				onClick={() => this.showTooltip()}
				onBlur={() => this.hideTooltip(2000)}
				onMouseEnter={() => this.showTooltip()}
				onMouseLeave={() => this.hideTooltip()}
			>
				{children ? children : 'No children provided'}
				{hover && this.renderTooltip()}
			</span>
		);
	}

	renderTooltip () {
		let { theme, style, content } = this.props;
		let toolTipStyle = Object.assign({}, {
			backgroundColor: theme.palette.primary5ColorV2,
			color: theme.palette.primary6ColorV2,
			borderRadius: 5,
			height: 'auto',
			width: 146,
			fontSize: 10,
			padding: 10,
			position: 'absolute',
			zIndex: 9999
		}, style || {});
		if (this.tooltip) {
			/*
				bottom: 483.5
				height: 100
				left: 298.171875
				right: 444.171875
				top: 383.5
				width: 146
				x: 298.171875
				y: 383.5
			 */
			// console.log(this.tooltip.getBoundingClientRect());
			// console.log(h.general.getScreenWidth());
			if (this.tooltip.getBoundingClientRect().y > h.general.getScreenWidth()) {
				toolTipStyle.right = 0;
				// toolTipStyle.transform = `translateX(${h.general.getScreenWidth()})`;
			}
		}
		return (<span
			style={toolTipStyle}
			ref={(el) => this.tooltip = el}
		>
			{content ? content : 'No content provided'}
		</span>);
	}

	showTooltip () {
		this.setState({hover: true});
	}

	hideTooltip (delay) {
		const self = this;
		if (delay && delay > 0) {
			setTimeout(() => {
				self.setState({hover: false});
			}, delay);
		} else {
			self.setState({hover: false});
		}
	}

}

PartialUITooltip.propTypes = {
	style: PropTypes.object,
	content: PropTypes.string
};

const mapStateToProps = state => ({
	// constants: state.constants,
	// currentUser: state.user,
	// currentEntity: state.company.currentEntity,
	theme: state.config.theme,
	// currentServiceProvider: state.serviceProvider ? state.serviceProvider.currentServiceProvider : null
});

const mapDispatchToProps = {
	// setBreadcrumbs: ConfigActions.setBreadcrumb
};

export default connect(mapStateToProps, mapDispatchToProps)(PartialUITooltip);

