export default function pushNotification (state = {}, action) {
    switch (action.type) {
        case 'SET_PUSH_NOTIFICATION_CONNECTION':
            return {
                ...state,
                connection: action.connection
            }

        default:
            return state;
    }
}