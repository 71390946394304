import React from 'react';
import { helpers } from './index';

var serviceprovider = {

    /**
     * detects for switching of service provider
     * @param nextProps
     * @param currentProps
     * @param executeFunction
     * @returns {boolean}
     */
    detectServiceProviderSwitch: function (nextProps, currentProps, executeFunction) {
        if (nextProps &&
            nextProps.currentServiceProvider &&
            nextProps.currentServiceProvider.service_provider_id&&
            currentProps &&
            currentProps.currentServiceProvider&&
            currentProps.currentServiceProvider.service_provider_id&&
            !helpers.general.cmpStr(nextProps.currentServiceProvider.service_provider_id, currentProps.currentServiceProvider.service_provider_id)) {
            // add delay to fix race condition where entity id is not set yet
            setTimeout(function () {
                executeFunction();
            }, 500);
            // true means entity switching detected
            return true;
        } else {
            // false means no entity switching detected
            return false;
        }
    },

    /**
     * Pretty partner tier name
     * @param {string} partnerTier
     * @returns {string}
     */
    prettifyPartnerTier: (partnerTier) => {
        let partnerTierName = 'partner';
        if (partnerTier && !helpers.cmpStr(partnerTier, 'partner')) {
            partnerTierName = `${partnerTier} partner`;
        }
        return partnerTierName;
    },

    /**
     * Check whether service provider user belongs to Lanturn
     * @param current_service_provider_id
     * @returns {*}
     */
    isLanturnStaff: (current_service_provider_id) => {

        return !helpers.cmpStr(helpers.general.getEnv(), 'production') || (current_service_provider_id && helpers.cmpStr(current_service_provider_id, '9873dbbb-6189-4649-8a0e-6faf7317984d'));
    }

};

export default serviceprovider;
