export default function timeline(state = {}, action) {
    switch (action.type) {
        case 'SET_TIMELINE_EVENTS':
            return {
                ...state,
                timelineEvents: action.timelineEvents || []
            };
        default:
            return state;
    }
}