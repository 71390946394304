import JSZip from 'jszip';
import { saveAs } from 'save-as';
import { general } from "./index";
import { config } from "../configs/config";

/**
 * Zip multiple remote files based on URLs provided and trigger a download of the zip file
 * @param {Array<string>}fileUrls
 * @param {string} customZipFileName
 * @returns {Promise<void>}
 */
export async function downloadRemoteFileUrlsAsZip (fileUrls = [], customZipFileName = 'attachments.zip') {
	const funcName = 'fileHelper.zipRemoteFileUrls';
	let count = 0;
	try {
		const zip = new JSZip();
		for (let i = 0; i < fileUrls.length; i++) {
			const fileUrl = fileUrls[i];
			const fileName = decodeURIComponent(general.getFileNameFromUrl(fileUrl));
			const downloadApiUrl = `${config.apiUrl}/v1.0.0/general/download`;
			const remoteFile = await fetch(downloadApiUrl, {
				method: 'post',
				headers: { 'content-type': "application/json" },
				body: JSON.stringify({ url: fileUrl })
			});
			const remoteFileData = remoteFile.blob();
			zip.file(fileName, remoteFileData, { binary: true });
			count++;
			if (count === fileUrls.length) {
				zip.generateAsync({ type: 'blob' }).then(function (content) {
					return saveAs(content, customZipFileName);
				});
			}
		}
	} catch (err) {
		console.log(err);
		console.error(`${funcName}: failed to zip remote file urls`, {err, fileUrls, customZipFileName});
	}
};
