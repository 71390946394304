import * as generalHelper from './general';
import * as dateHelper from './date';
import * as apiHelper from './api';
import * as authHelper from './auth';
import * as validateHelper from './validate';
import tableHelper from './table';
import companyHelper from './company';
import serviceProviderHelper from './serviceprovider';
import * as formHelper from './form';
import * as historyHelper from './history';
import * as currencyHelper from './currency';
import userHelper from './user';
import pricingHelper from './pricing';
import * as memberHelper from './member';
import * as taskHelper from './task';
import * as spChatHelper from './spChat';
import * as iconHelper from './icon';
import * as documentGenerationHelper from './documentGeneration';
import * as billingSubscriptionHelper from './billingSubscription';
import * as stripeHelper from './stripe';
import * as fileHelper from './file';

export const helpers = {
    general: generalHelper,
    date: dateHelper,
    api: apiHelper,
    auth: authHelper,
    validate: validateHelper,
    company: companyHelper,
    serviceprovider: serviceProviderHelper,
    form: formHelper,
    history: historyHelper,
    currency: currencyHelper,
    member: memberHelper,
    // logHelper: logHelper,
    user: userHelper,
    table: tableHelper,
    pricing: pricingHelper,
    task: taskHelper,
    spChat: spChatHelper,
    icon: iconHelper,
    docGen: documentGenerationHelper,
    billingSubscription: billingSubscriptionHelper,
    stripe: stripeHelper,
    file: fileHelper,
    // general helper functions to expose on top level
    log: generalHelper.log,
    isEmpty: generalHelper.isEmpty,
    notEmpty: generalHelper.notEmpty,
    cmpStr: generalHelper.compareString,
    cmpInt: generalHelper.compareInt,
    cmpBool: generalHelper.compareBoolean,
    printJsonObject: generalHelper.printJsonObject,
    printJsonArray: generalHelper.printJsonArray
};

export const h = {
    general: generalHelper,
    date: dateHelper,
    api: apiHelper,
    auth: authHelper,
    validate: validateHelper,
    company: companyHelper,
    serviceprovider: serviceProviderHelper,
    form: formHelper,
    history: historyHelper,
    currency: currencyHelper,
    member: memberHelper,
    // logHelper: logHelper,
    user: userHelper,
    table: tableHelper,
    pricing: pricingHelper,
    task: taskHelper,
    spChat: spChatHelper,
    icon: iconHelper,
    docGen: documentGenerationHelper,
    billingSubscription: billingSubscriptionHelper,
    file: fileHelper,
    // general helper functions to expose on top level
    log: generalHelper.log,
    isEmpty: generalHelper.isEmpty,
    notEmpty: generalHelper.notEmpty,
    cmpStr: generalHelper.compareString,
    cmpInt: generalHelper.compareInt,
    cmpBool: generalHelper.compareBoolean,
    printJsonObject: generalHelper.printJsonObject,
    printJsonArray: generalHelper.printJsonArray
};

export const general = generalHelper;
export const date = dateHelper;
export const api = apiHelper;
export const auth = authHelper;
export const validate = validateHelper;
export const company = companyHelper;
export const form = formHelper;
export const history = historyHelper;
export const currency = currencyHelper;
// export const log = logHelper;
export const user = userHelper;
// export const pricing = pricingHelper;
export const table = tableHelper;
export const task = taskHelper;
export const spChat = spChatHelper;
export const icon = iconHelper;
export const billingSubscription = billingSubscriptionHelper;
export const stripe = stripeHelper;
export const file = fileHelper;
