import $ from 'jquery';
import { config } from '../configs/config';

/**
 * get company information
 * @param data
 * @param callback
 */
export function getCompanyInfoSGP (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-business-info',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check company home url availability
 * @param data
 * @param callback
 */
export function checkHomeUrl (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/check-url-availability',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company profile
 * @param data
 * @param callback
 */
export function getCompanyProfile (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-profile',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update company profile
 * @param data
 * @param callback
 */
export function updateCompanyProfile (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/company/update-profile',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity reps
 * @param data
 * @param callback
 */
export function getCompanyRep (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-entity-reps',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update company entity reps
 * @param data
 * @param callback
 */
export function updateCompanyRep (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/update-entity-reps',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company users
 * @param data
 * @param callback
 */
export function getUsers (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-users',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create company user
 * @param data
 * @param callback
 */
export function createUser (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/create-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * delete company user
 * @param data
 * @param callback
 */
export function deleteUser (user_id, entity_id, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + '/v1.0.0/company/delete-user?user_id=' + user_id + '&entity_id=' + entity_id,
        dataType: "json",
        // data: data,
        error: callback
    }).done(callback);
}

/**
 * get company bookkeepers
 * @param data
 * @param callback
 */
export function getBookkeepers (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-bookkeepers',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * resend user invitation
 * @param data
 * @param callback
 */
export function resendUserInvite (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/resend-user-invite',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entities
 * @param data
 * @param callback
 */
export function getCompanies (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/staff/company/get-companies',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company users
 * @param data
 * @param callback
 */
export function getCompanyUsers (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/staff/company/get-company-users',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entities
 * @param data
 * @param callback
 */
export function getEntities (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-entities',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entities
 * @param data
 * @param callback
 */
export function getEntityUsersByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-entity-users-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity by entity id
 * @param data
 * @param callback
 */
export function getEntityByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-entity',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * save company entity provider
 * @param data
 * @param callback
 */
export function saveEntityProvider (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/staff/company/update-entity-provider',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * save company entity provider
 * @param data
 * @param callback
 */
export function saveEntityUserProvider (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/update-entity-user-provider',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update entity
 * @param data
 * @param callback
 */
export function updateEntity (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/update-entity',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update entity services
 * @param data
 * @param callback
 */
export function updateEntityServices (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/update-entity-services',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * save company
 * @param data
 * @param callback
 */
export function saveCompany (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/staff/company/save',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * disconnect Xero from entity
 * @param data
 * @param callback
 */
export function disconnectXero (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/disconnect-xero',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get bill, expensess, and task notification by entity id
 * @param data
 * @param callback
 */
export function getNotifications (data, callback) {
    data.is_silent_request = 1;
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-notifications',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * save setting by entity
 * @param data
 * @param callback
 */
export function saveSetting (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/company/save-setting',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity directors by entity id
 * @param data
 * @param callback
 */
export function getDirectorsByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/entity/get-directors',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * complete director kyc process
 * @param data
 * @param callback
 */
export function completeDirectorKyc (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/staff/company/entity/director/kyc',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get company entity shareholders by entity id
 * @param data
 * @param callback
 */
export function getShareholdersByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/entity/get-shareholders',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * complete shareholder kyc process
 * @param data
 * @param callback
 */
export function completeShareholderKyc (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/staff/company/entity/shareholder/kyc',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get incorporation forms
 * @param data
 * @param callback
 */
export function getIncorporationForms (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-incorporation-forms',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create company entity user
 * @param data
 * @param callback
 */
export function createEntityUser (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/entity/user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * delete company entity
 * @param {string} entityId
 * @param callback
 */
export function deleteEntity (entityId, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/entity?entity_id=' + entityId,
        dataType: "json",
        error: callback
    }).done(callback);
}

/**
 * delete company entity user
 * @param entityId
 * @param userId
 * @param callback
 */
export function deleteEntityUser (entityId, userId, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/entity/user?user_id=' + userId + '&entity_id=' + entityId,
        dataType: "json",
        error: callback
    }).done(callback);
}

/**
 * create entity
 * @param data
 * @param callback
 */
export function createEntity (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/entity',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * search registration number according to jurisdiction's government records
 * @param data
 * @param callback
 */
export function searchRegistrationNumber (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/entity/search-registration-number',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * search for existing entities using company name
 * @param data
 * @param callback
 */
export function searchCompanyName (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/search-company-name',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getEntityMemberDirectors (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/company/get-entity-member-directors',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);

}

/**
 * search for existing entities using company name
 * @param data
 * @param callback
 */
export function getRegisters(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/get-company-entity-registers',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create controllers significant control
 * @param data
 * @param callback
 */
export function createControllersSignificantControl(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/create-controllers-significant-control',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create officers
 * @param data
 * @param callback
 */
export function createOfficers(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/create-officers',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create nominee directors
 * @param data
 * @param callback
 */
export function createNomineeDirectors(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/create-nominee-directors',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create charges
 * @param data
 * @param callback
 */
export function createCharges(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/create-charges',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}


/**
 * delete controllers significant control by id
 * @param data
 * @param callback
 */
export function deleteControllersSignificantControlById(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/company/delete-controllers-significant-control-by-id?company_entity_register_id=${data.company_entity_register_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * delete officers by id
 * @param data
 * @param callback
 */
export function deleteOfficersById(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/company/delete-officers-by-id?company_entity_register_id=${data.company_entity_register_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * delete nominee directors by id
 * @param data
 * @param callback
 */
export function deleteNomineeDirectorsById(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/delete-nominee-directors-by-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * delete charges by id
 * @param data
 * @param callback
 */
export function deleteChargesById(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/company/delete-charges-by-id?company_entity_register_charge_id=${data.company_entity_register_charge_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * Initialise email shortener for user in entity
 * @param {{entity_id:string, user_id:string}} data
 * @param callback
 */
export function initEmailShortener (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/init-email-shortener',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * Set domain in email shortener for user in entity
 * @param {{entity_id:string, user_id:string, domain:string}} data
 * @param callback
 */
 export function setEmailShortenerDomain (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/company/set-email-shortener-domain',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
