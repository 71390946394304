import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import config from './config';
import constants from './constants';
import permission from './permission';
import user from './user';
import company from './company';
import expense from './expense';
import bankTransaction from './bankTransaction';
import task from './task';
import privateTask from './privateTask';
import demo from './demo';
import notification from './notification';
import timeline from './timeline';
import serviceProvider from './serviceProvider';
import pushNotification from './pushNotification';
import analytics from './analytics';

export default combineReducers({
    config,
    constants,
    permission,
    user,
    company,
    expense,
    bankTransaction,
    task,
    privateTask,
    demo,
    form: formReducer,
    notification,
    timeline,
    serviceProvider,
    pushNotification,
    analytics
});
