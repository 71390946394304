import $ from 'jquery';
import { config } from '../configs/config';

/**
 * check members email address availability
 * @param data
 * @param callback
 */
export function checkMembersEmailAvailability (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/check-members-email-availability',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get member identities by service provider id
 * @param data
 * @param callback
 */
export function getMemberIdentitiesByServiceProviderId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-member-identities-by-service-provider-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get member identitiy details by member identity id
 * @param data
 * @param callback
 */
export function getMemberIdentityDetailsByMemberIdentityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-member-identity-details-by-member-identity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get member document details by member identity document id
 * @param data
 * @param callback
 */
export function getMemberDocumentDetailsByMemberIdentityDocumentId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-member-document-details-by-member-identity-document-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get member identities by entity id
 * @param data
 * @param callback
 */
export function getMemberIdentitiesByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-member-identities-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get member identities by entity id
 * @param data
 * @param callback
 */
export function getMemberIdentityShareholdersByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/member/get-member-identity-shareholders-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get member identities by entity id
 * @param data
 * @param callback
 */
export function getActiveMemberIdentitiesByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-active-member-identities-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get members directors by entity id
 * @param data
 * @param callback
 */
export function getMembersDirectorsByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-members-directors-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get members directors by member identity entity director id
 * @param data
 * @param callback
 */
export function getMembersDirectorsByMemberIdentityEntityDirectorId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-members-directors-by-member-identity-entity-director-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create members directors by entity id
 * @param data
 * @param callback
 */
export function createMembersDirectorsByEntityId(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/create-members-directors-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update members directors by member identity entity director id
 * @param data
 * @param callback
 */
export function updateMembersDirectorsByMemberIdentityEntityDirectorId(data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/update-members-directors-by-member-identity-entity-director-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * delete members directors by member identity entity director id
 * @param data
 * @param callback
 */
export function deleteMembersDirectorsByMemberIdentityEntityDirectorId(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/member/delete-members-directors-by-member-identity-entity-director-id?member_identity_entity_director_id=${data.memberIdentityEntityDirectorId}&member_identity_fk=${data.memberIdentityId}&entity_fk=${data.entityId}&created_by=${data.created_by}&updated_by=${data.updated_by}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get transactions by entity id
 * @param data
 * @param callback
 */
export function getTransactionsByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-transactions-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get cap table by entity id
 * @param data
 * @param callback
 */
export function getCapTableByEntityId (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-cap-table-by-entity-id',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * send member kyc
 * @param data
 * @param callback
 */
export function sendMemberKYC (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/send-member-kyc',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
export function checkMemberDetails(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/check-member-details',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
export function createIndividualMemberIdentity(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/create-member-identity-individual',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getIndividualMemberIdentity(data, callback) {

}

export function updateIndividualMemberIdentity(data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/update-member-identity-individual',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function createCorporateMemberIdentity(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/create-member-identity-corporate',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getCorporateMemberIdentity(data, callback) {

}

export function updateCorporateMemberIdentity(data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/update-member-identity-corporate',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function mergeMemberIdentities(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/merge-member-identities',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function deleteMemberIdentity(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/member/delete-member-identity?member_identity_id=${data.member_identity_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function createTransaction(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/create-transaction',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getTransaction(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-transaction',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function updateTransaction(data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/update-transaction',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function deleteTransaction(data, callback) {
    let {member_identity_entity_share_allocation_log_id} = data;
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/member/delete-transaction?member_identity_entity_share_allocation_log_id=${member_identity_entity_share_allocation_log_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function createMemberIdentityConnection(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/add-member-identity-connections',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);

}

export function getMemberIdentityConnection(data, callback) {

}

export function getMemberIdentityConnections(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/get-member-identity-connections',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function updateMemberIdentityConnection(data, callback) {

}

export function deleteMemberIdentityConnection(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/member/delete-member-identity-connection?member_identity_connection_id=${data.member_identity_connection_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function getDocumentInfo(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/entity/get-document-info',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function deleteDocuemntByMemberIdentityDocumentId(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/member/delete-document-by-member-identity-document-id?member_identity_document_id=${data.member_identity_document_id}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function deleteMemberIdentityDetails(data, callback) {
    $.ajax({
        method: "DELETE",
        url: config.apiUrl + `/v1.0.0/serviceProvider/member/delete-member-identity-details?member_identity_fk=${data.member_identity_fk}&type=${data.type}&value=${data.value}`,
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

export function findExistingMember(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/member/find-existing-member',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
