import * as React  from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router-dom';
import FontIcon from 'material-ui/FontIcon';
import ExclamationMark from "../assets/img/icons/icon-exclamation-mark.svg";

//Generic mappings
const mapStateToProps = state => ({
    constants: state.constants,
    currentUser: state.user,
    theme: state.config.theme
});
const mapDispatchToProps = {
};

//Components
/**
 * @typedef FADeleteProps
 * @property {React.EventHandler<React.MouseEvent>} onClick
 * @extends {React.Component<FADeleteProps>}
 */
class _FADelete extends React.Component {
    render () {
        let onClick = this.props.onClick || (() => { console.error('onClick not implemented'); });
        let theme = this.props.theme || {};
        return (
            <FontAwesome
                style={{cursor: 'pointer', color: theme.palette.dangerColor, marginLeft: 9}}
                onClick={() => onClick()}
                name='trash'
                size='2x'
            />
        );
    }
}
export const FADelete = connect(mapStateToProps, mapDispatchToProps)(withRouter(_FADelete));

/**
 * @typedef FAUpdateProps
 * @property {React.EventHandler<React.MouseEvent>} onClick
 * @extends {React.Component<FAUpdateProps>}
 */
class _FAUpdate extends React.Component {
    render () {
        let onClick = this.props.onClick || (() => { console.error('onClick not implemented'); });
        let theme = this.props.theme || {};
        return (
            <FontAwesome
                style={{cursor: 'pointer', color: theme.palette.infoColor, marginLeft: 9}}
                onClick={onClick}
                name='pencil-square-o'
                size='2x'
            />
        );
    }
}

export const FAUpdate = connect(mapStateToProps, mapDispatchToProps)(withRouter(_FAUpdate));

/**
 * @typedef MUIconDeleteProps
 * @property {React.EventHandler<React.MouseEvent>} onClick
 * @extends {React.Component<MUIconDeleteProps>}
 */
class _MUIconDelete extends React.Component {
    render() {
        let onClick = this.props.onClick || (() => { console.error('onClick not implemented'); });
        let theme = this.props.theme || {};
        return (
            <FontIcon
                className="material-icons"
                style={{cursor: 'pointer', color: theme.palette.dangerColor}}
                onClick={onClick}
            >delete</FontIcon>
        );
    }
}
export const MUIconDelete = withRouter(connect(mapStateToProps, mapDispatchToProps)(_MUIconDelete));

export class IconExclamationMark extends React.Component {
    render() {
        return (
            <div>
                <img style={{width: 50, marginTop: 5, marginRight: 14}} src={ExclamationMark}/>
            </div>
        );
    }
}

/**
 * @typedef MUIconVisibilityProps
 * @property {React.EventHandler<React.MouseEvent>} onClick
 * @property {Boolean} visible
 * @extends {React.Component<MUIconVisibilityProps, {}>}
 */
class _MUIconVisibility extends React.Component {
    render() {
        let onClick = this.props.onClick || (() => { console.error('onClick not implemented'); });
        let theme = this.props.theme || {};
        let icon = this.props.visible ? 'visibility': 'visibility_off';
        let color = this.props.visible ? theme.palette.primary1Color : 'grey';
        return (
            <FontIcon
                className="material-icons"
                style={{cursor: 'pointer', color}}
                onClick={onClick}
            >{icon}</FontIcon>
        );
    }
}
export const MUIconVisibility = withRouter(connect(mapStateToProps, mapDispatchToProps)(_MUIconVisibility));