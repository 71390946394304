import * as general from '../helpers/general';
import _ from 'lodash';
import moment from 'moment';

const months = [
    { month: 1, partial: "Jan", full: "January" },
    { month: 2, partial: "Feb", full: "February" },
    { month: 3, partial: "Mar", full: "March" },
    { month: 4, partial: "Apr", full: "April" },
    { month: 5, partial: "May", full: "May" },
    { month: 6, partial: "Jun", full: "June" },
    { month: 7, partial: "Jul", full: "July" },
    { month: 8, partial: "Aug", full: "August" },
    { month: 9, partial: "Sep", full: "September" },
    { month: 10, partial: "Oct", full: "October" },
    { month: 11, partial: "Nov", full: "November" },
    { month: 12, partial: "Dec", full: "December" },
];

/**
 * generate list of months for select input
 * @returns {Array}
 */
export function getMonths () {
    var monthsData = [];
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        monthsData.push({
            value: month.month,
            text: _.padStart(month.month, 2, '0')
        });
    }
    return monthsData;
}

/**
 * generate list of months for select input
 * @returns {Array}
 */
export function getMonthsWithLabelName () {
    var monthsData = [];
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        monthsData.push({
            value: month.month,
            text: month.full
        });
    }
    return monthsData;
}

/**
 * generate list of years for select input
 * @returns {Array}
 */
export function getYears () {
    var currentYear = parseInt((new Date()).getFullYear(), 10);
    var yearsData = [];
    for (var i = currentYear; i <= (currentYear + 20); i++) {
        yearsData.push({
            value: String(i),
            text: String(i)
        });
    }
    return yearsData;
}

/**
 * get difference between two dates
 * @param from
 * @param to
 * @returns {number}
 */
export function getDaysBtwDates (from, to) {
    if (typeof from !== typeof undefined && typeof to !== typeof undefined) {
        to = moment(to);
        from = moment(from);

        var toMonthYear = new Date(to.toDate()),
            fromMonthYear = new Date(from.toDate());

        toMonthYear.setYear((new Date()).getFullYear());
        if (toMonthYear > fromMonthYear) {
            return Math.abs(moment(fromMonthYear).diff(moment(toMonthYear), 'days')) + 1;
        } else {
            toMonthYear.setYear((new Date()).getFullYear()+1);
            return Math.abs(moment(fromMonthYear).diff(moment(toMonthYear), 'days')) + 1;
        }
    } else {
        return 0;
    }
}

export function calculateAGM_SG (yearEnd) {
    return yearEnd ? moment(yearEnd).add(6, 'M') : '';
}

export function calculateAR_SG (agm) {
    return agm ? moment(agm).add(1, 'M') : '';
}

export function calculcateECI_SG (yearEnd) {
    return yearEnd ? moment(yearEnd).add(3, 'M').endOf('month') : '';
}

/**
 * formats date display
 * @param date
 * @param [format]
 * @param [isUTC]
 * @returns {string}
 */
export function format (date, format, isUTC) {
    if(!moment(date).isValid()) {
        return ''
    }
    format = typeof format !== typeof undefined ? format : 'DD-MM-YYYY';
    isUTC = typeof isUTC !== typeof undefined ? isUTC : false;
    var momentDate = isUTC ? moment(moment.utc(date).toDate()).format(format) : moment(date).format(format);
    return momentDate;
}

/**
 * formats financial year end date
 * @param date
 * @returns {string}
 */
export function formatFinancialYearEnd (date) {
    var currentMonth = date.getMonth() + 1,
        monthText = "";
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        if (parseInt(currentMonth, 10) === parseInt(month.month, 10)) {
            monthText = general.ucFirst(month.full);
        }
    }
    return date.getDate() + " " + monthText;
}

/**
 * formats financial year end date with year added
 * @param date
 * @returns {string}
 */
export function formatFinancialYearEndWithYear (date) {
    var currentMonth = date.getMonth() + 1,
        monthText = "";
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        if (parseInt(currentMonth, 10) === parseInt(month.month, 10)) {
            monthText = general.ucFirst(month.full);
        }
    }
    return date.getDate() + " " + monthText + " " + date.getFullYear();
}

/**
 * formats date to DD MMMM YYYY
 * @param date
 * @returns {string}
 */
export function formatDateOfBirth (date) {
    var currentMonth = date.getMonth() + 1,
        monthText = "";
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        if (parseInt(currentMonth, 10) === parseInt(month.month, 10)) {
            monthText = general.ucFirst(month.full);
        }
    }
    return date.getDate() + " " + monthText + " " + date.getFullYear();
}

/**
 * formats subscription date
 * @param date
 * @returns {string}
 */
export function formatSubscriptionDate (date) {
    var currentMonth = date.getMonth() + 1,
        monthText = "";
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        if (parseInt(currentMonth, 10) === parseInt(month.month, 10)) {
            monthText = general.ucFirst(month.full);
        }
    }
    return date.getDate() + " " + monthText;
}

/**
 * formats incorporation date
 * @param date
 * @returns {string}
 */
export function formatIncorporationDate (date) {
    var currentMonth = date.getMonth() + 1,
        monthText = "";
    for (var i = 0; i < months.length; i++) {
        var month = months[i];
        if (parseInt(currentMonth, 10) === parseInt(month.month, 10)) {
            monthText = general.ucFirst(month.full);
        }
    }
    return date.getDate() + " " + monthText + " " + date.getFullYear();
}

/**
 * formats credit card expiry date
 * @param date
 * @returns {string}
 */
export function formatCCExpiryDate (date) {
    return (date.getMonth() + 1) + "/" + date.getFullYear();
}

/**
 * add year to date
 * @param date
 * @param years
 * @returns {*}
 */
export function addYearToDate (date, years) {
    if (general.notEmpty(date) && general.notEmpty(years)) {
        var momentDate = moment(date);
        momentDate.add(years, 'years');
        return momentDate;
    } else {
        return '';
    }
}

/**
 * add day to date
 * @param date
 * @param months
 * @returns {*}
 */
export function addDayToDate (date, months) {
    if (general.notEmpty(date) && general.notEmpty(months)) {
        var momentDate = moment(date);
        momentDate.add(months, 'days');
        return momentDate;
    } else {
        return '';
    }
}

/**
 * add month to date
 * @param date
 * @param months
 * @returns {*}
 */
export function addMonthToDate (date, months) {
    if (general.notEmpty(date) && general.notEmpty(months)) {
        var momentDate = moment(date);
        momentDate.add(months, 'months');
        return momentDate;
    } else {
        return '';
    }
}


/**
 * subtract day from date
 * @param date
 * @param days
 * @returns {*}
 */
export function subtractDayFromDate (date, days) {
    if (general.notEmpty(date) && general.notEmpty(days)) {
        var momentDate = moment(date);
        momentDate.subtract(days, 'days');
        return momentDate;
    } else {
        return '';
    }
}

/**
 * add months to a date
 * @param inDate
 * @param monthsToAdd
 * @param returnType
 * @returns {any}
 */
export function addMonthsToDate (inDate, monthsToAdd, returnType) {
    returnType = general.isEmpty(returnType) ? 1 : returnType;
    var newDate = inDate ? moment(inDate).add({months: monthsToAdd}) : '';
    switch (returnType) {
        // returns moment date object
        default:
        case 1:
            if (general.isEmpty(newDate)) {
                newDate = moment();
            }
            break;
        // returns javascript date object
        case 2:
            if (!general.isEmpty(newDate)) {
                return newDate.toDate();
            } else {
                newDate = new Date();
            }
            break;
    }
    return newDate;
}

/**
 * calculate default financial year end date
 * @returns {*|moment.Moment}
 */
export function calculateDefaultFYE_SG () {
    var currentDate = new Date();
    var momentDate = moment(currentDate);
    // add 11 months to current month
    momentDate = addMonthsToDate(momentDate, 11);
    // get last day of month
    momentDate = momentDate.endOf('month');
    return momentDate;
}

/**
 * prepares date for submission to api disregarding all timezone involved
 * @param date
 * @returns {string}
 */
export function formatUTCDateForApi (date) {
    if (general.notEmpty(date)) {
        date = date instanceof Date ? date : new Date(date);
        // strips timezone date from date string
        // return date.toDateString();
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    } else {
        return '';
    }
}

/**
 * Format UTC version of datetime to local version of datetime
 * @param {Date|String} utcDateStr
 * @returns {string}
 */
export function formatUTCToLocalDateTime (utcDateStr) {
    if (general.notEmpty(utcDateStr)) {
        var date = utcDateStr instanceof Date ? utcDateStr : new Date(utcDateStr);
        var monthText = '';
        for (var i = 0; i < months.length; i++) {
            var month = months[i];
            if (parseInt((date.getMonth() + 1), 10) === parseInt(month.month, 10)) {
                monthText = general.ucFirst(month.full);
            }
        }
        return date.getDate() + ' ' + monthText + ' ' + date.getFullYear() + ' ' + formatAMPM(date);
    } else {
        return '';
    }
}

/**
 * Format UTC version of datetime to local version of datetime with offset
 * @param {Date|String} utcDateStr
 * @returns {string}
 */
export function formatUTCToLocalDateTimeWithOffset (utcDateStr) {
    if (general.notEmpty(utcDateStr)) {
        var date = utcDateStr instanceof Date ? utcDateStr : new Date(utcDateStr);
        var monthText = '';
        for (var i = 0; i < months.length; i++) {
            var month = months[i];
            if (parseInt((date.getMonth() + 1), 10) === parseInt(month.month, 10)) {
                monthText = general.ucFirst(month.full);
            }
        }

        const offset = `(UTC${(date.getTimezoneOffset()/60 < 0) ? '+':'-'}0${Math.abs(date.getTimezoneOffset()/60)})`;
        const dateTime = date.getDate() + ' ' + monthText + ' ' + date.getFullYear() + ' ' + formatAMPM(date);

        return dateTime + ' ' + offset;
    } else {
        return '';
    }
}

/**
 * Format AM/PM in date
 * @param {Date|String} date
 * @returns {string}
 */
export function formatAMPM (date) {
    if (general.notEmpty(date)) {
        date = date instanceof Date ? date : new Date(date);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    } else {
        return '';
    }
}
/**
 *
 * @param {Date | string} utcDateStr
 */
export function formatUTCToLocalDateTimeWithTimezone (utcDateStr) {
    if (general.notEmpty(utcDateStr)) {
        var date = utcDateStr instanceof Date ? utcDateStr : new Date(utcDateStr);
        var monthText = '';
        for (var i = 0; i < months.length; i++) {
            var month = months[i];
            if (date.getMonth() + 1 === month.month) {
                monthText = general.ucFirst(month.full);
            }
        }
        let timezone = getTimeZoneStringFromDate(date);
        return date.getDate() + ' ' + monthText + ' ' + date.getFullYear() + ' ' + formatAMPM(date) + ` (${timezone})`;
    } else {
        return '';
    }
}
/**
 * Gets the timezone string from date
 * @param {Date} date
 */
function getTimeZoneStringFromDate (date) {
    let date_str = date.toString();
    let timezone_regex = /(\()([^()]+)(\))/;
    let matches = timezone_regex.exec(date_str);
    let timezone_only = matches[2] || '';
    return timezone_only.trim();
};

/**
 * Get time difference between two dates
 * @param {Date} date1
 * @param {Date} date2
 * @param {String} [unit='milliseconds']
 * @returns {number}
 */
export function timeDiffBetweenTwoDates (date1, date2, unit) {
    unit = unit || 'milliseconds';
    // the following is to handle cases where the times are on the opposite side of
    // midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM
    if (date2 < date1) {
        date2.setDate(date2.getDate() + 1);
    }
    const diff = date2 - date1;
    let formattedDiff = null;
    switch (unit) {
        case 'seconds':
            formattedDiff = Math.floor((diff / 1000) % 60);
            break;
        case 'minutes':
            formattedDiff = Math.floor((diff / (1000 * 60)) % 60);
            break;
        case 'hours':
            formattedDiff = Math.floor((diff / (1000 * 60)) % 60);
            break;
        default:
            formattedDiff = diff;
    }
console.log('formattedDiff =', formattedDiff);
    formattedDiff = formattedDiff < 10 ? 0 : formattedDiff;
    return formattedDiff;
}
