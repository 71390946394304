import React from 'react';
import { config } from '../configs/config';
import accountsNinjaTheme from '../themes/accountsNinjaTheme';
import { helpers, h } from './index';
import { Link } from 'react-router-dom';

var company = {

    /**
     * generates company home url based on company name
     * @param companyName
     * @returns {*}
     */
    generateCompanyUrl (companyName) {
        const unwantedNames = ['pte', 'llp', 'lp', 'limited', 'ltd', 'll', 'private'];
        if (typeof companyName === typeof undefined || companyName === '') {
            return '';
        }
        companyName = companyName.toLowerCase();

        // remove all dots from company name
        companyName = companyName.split('.').join('');
        // remove all commas from company name
        companyName = companyName.split(',').join('');
        // remove all brackets from company name
        companyName = companyName.split('(').join('');
        companyName = companyName.split(')').join('');

        // remove unwanted company entity types
        var companyNameStrArray = companyName.split(' ');
        for (var i = 0; i < companyNameStrArray.length; i++) {
            for (var j = 0; j < unwantedNames.length; j++) {
                if (String(companyNameStrArray[i]).toLowerCase() === String(unwantedNames[j]).toLowerCase()) {
                    delete companyNameStrArray[i];
                }
            }
        }
        // merge array back to a single string
        var newCompanyName = companyNameStrArray.join('');

        // remove unwanted characters
        newCompanyName = newCompanyName.replace(/\W/g, '');

        return newCompanyName;
    },

    /**
     * get full company home url
     * @param prefix
     * @returns {*}
     */
    getCompanyUrl (prefix) {
        if (typeof prefix === typeof undefined || prefix === '') {
            return '';
        }
        var companyHomeUrl = '';

        if (config.env === 'development') {
            companyHomeUrl = 'https://' + prefix + '.' + (config.webappUrl.replace('https://', ''))
        } else {
            companyHomeUrl = 'https://' + prefix + '.' + (config.webappUrl.replace('https://app.', ''))
        }

        return companyHomeUrl;
    },

    /**
     * format status color for capture status
     * @param constant
     * @param status
     * @returns {string}
     */
    formatCaptureStatus (constant, status) {
        var formattedStatus = '';
        if (helpers.general.notEmpty(constant) &&
            helpers.general.notEmpty(constant.CAPTURE_STATUS) &&
            helpers.general.notEmpty(status)) {
            switch (status) {
                case constant.CAPTURE_STATUS.PENDING:
                    formattedStatus =
                        <span style={{color: accountsNinjaTheme.palette.accent2Color}}>
                        {helpers.general.prettifyConstant(status).toUpperCase()}
                    </span>;
                    break;
                case constant.CAPTURE_STATUS.SENT:
                    formattedStatus =
                        <span style={{color: accountsNinjaTheme.palette.successColor}}>
                        {helpers.general.prettifyConstant(status).toUpperCase()}
                    </span>;
                    break;
            }
        }
        return formattedStatus;
    },

    /**
     * generate view entity url
     * @param entityId
     * @returns {string}
     */
    generateViewEntityUrl (entityId) {
        var url = '';
        if (helpers.general.notEmpty(entityId)) {
            url = <span>(<Link to={'/entities/' + entityId}>View Entity</Link>)</span>;
        }
        return url;
    },

    /**
     * helper function to format company/entity name
     * @param entity
     * @param [isTruncate]
     * @param [truncateLength]
     * @returns {*}
     */
    formatEntityName: function (entity, isTruncate, truncateLength) {
        if (h.isEmpty(entity) && h.isEmpty(entity.entity_name)) {
            return '';
        }
        isTruncate = h.notEmpty(isTruncate) && h.cmpBool(isTruncate, true) ? true : false;
        truncateLength = h.notEmpty(truncateLength) ? truncateLength : undefined;
        let entityName = entity.entity_name;
        const isIncorporated = h.notEmpty(entity.entity_status) && !h.cmpStr(entity.entity_status, 'pending') ? true : false;
        const entityNameSuffix = entity.entity_name_suffix;
        const countryCode = entity.entity_country_code;

        if (h.cmpBool(isIncorporated, false)) {
            entityName = `(pending) ${entityName}`;
        }
        if (h.notEmpty(entityNameSuffix)) {
            entityName = `${entityName} ${entityNameSuffix}`;
        }
        if (h.cmpBool(isTruncate, true)) {
            entityName = h.general.truncate(entityName, truncateLength);
        }
        if (!h.isEmpty(countryCode)) {
            let prettyCountryCode = '';
            switch (countryCode) {
                case '00':
                    prettyCountryCode = 'Unassigned';
                    break;
                case '01':
                    prettyCountryCode = 'Other';
                    break;
                case 'VG':
                    prettyCountryCode = 'BVI';
                    break;
                default:
                    prettyCountryCode = countryCode;
            }
            entityName = `${entityName} (${prettyCountryCode})`;
        }
        return entityName;
    }

};

export default company;
