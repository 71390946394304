import { compareString } from './general.js';

/**
 * Get billing subscription pricing by country code
 * @param {pricings?: Array<{amount: number, country_code: string}>} billingSubscription
 * @param {string} countryCode
 * @returns {string}
 */
export function getPriceByCountry (billingSubscription, countryCode) {
	let subscriptionPrice = '';
	if (
		billingSubscription &&
		billingSubscription.pricings &&
		billingSubscription.pricings.length > 0
	) {
		countryCode = countryCode && !['sg','au','hk','my','us'].includes(countryCode.toLowerCase()) ? 'us' : countryCode;
		for (let i = 0; i < billingSubscription.pricings.length; i++) {
			const pricing = billingSubscription.pricings[i];
			if (pricing.amount > 0 && compareString(pricing.country_code, countryCode)) {
				subscriptionPrice = pricing.amount_pretty;
			}
		}
		return subscriptionPrice;
	} else {
		return subscriptionPrice;
	}
}

/**
 * Get possible billing subscription pricings for an entity by entity's country code
 * @param {Array} billingSubscriptions
 * @param {string} entityCountryCode
 */
export function getEntityPlansByCountry (CommonConstant, billingSubscriptions, entityCountryCode) {
	entityCountryCode = entityCountryCode && !['sg','au','hk','my','us'].includes(entityCountryCode.toLowerCase()) ? 'us' : entityCountryCode;
	let plans = {};
	plans[CommonConstant.SUBSCRIPTION.PLAN.ZAVE_STARTER] = '';
	plans[CommonConstant.SUBSCRIPTION.PLAN.ZAVE_STANDARD] = '';
	plans[CommonConstant.SUBSCRIPTION.PLAN.ZAVE_ENTERPRISE] = '';
	for (let i = 0; i < billingSubscriptions.length; i++) {
		const billingSubscription = billingSubscriptions[i];
		if (billingSubscription && billingSubscription.pricings && billingSubscription.pricings.length > 0) {
			for (let i = 0; i < billingSubscription.pricings.length; i++) {
				const pricing = billingSubscription.pricings[i];
				if (compareString(pricing.country_code, entityCountryCode)) {
					plans[billingSubscription.billing_subscription_identifier] = pricing.amount_pretty;
				}
			}
		}
	}
	return plans;
}
