import * as general from './general';

var userHelper = {

    /**
     * checks that user has view permission
     * @param constant
     * @param permissions
     * @param pathname
     * @returns {boolean}
     */
    hasViewPermission: function (constant, permissions, pathname) {
        var currentPathname = '';
        var moduleName = '';
        if (constant &&
            constant.MODULES &&
            constant.PERMISSIONS &&
            permissions) {
            currentPathname = pathname || window.location.pathname;
            if (general.isEmpty(currentPathname)) {
                return false;
            }
            // route to module mapping
            switch (currentPathname) {
                default:
                case '/compliance':
                    moduleName = constant.MODULES.COMPLIANCE;
                    break;
                case '/admin-settings':
                    moduleName = constant.MODULES.ADMIN_SETTINGS;
                    break;
                case '/vitals':
                    moduleName = constant.MODULES.FINANCIAL_INDEX;
                    break;
                case '/bills':
                    moduleName = constant.MODULES.BILL_INDEX;
                    break;
            }
            if (permissions[moduleName] &&
                general.cmpBool(permissions[moduleName][constant.PERMISSIONS.VIEW], true)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },

    /**
     * formats user's first name and last name
     * @param data
     */
    formatFirstLastName: function (data) {
        var formattedName = '';
        if (general.notEmpty(data.first_name)) {
            formattedName += data.first_name;
        }
        if (general.notEmpty(data.last_name)) {
            if (general.notEmpty(formattedName)) {
                formattedName += ' ' + data.last_name;
            } else {
                formattedName += data.first_name;
            }
        }
        formattedName = general.ucFirstAllWords(formattedName);
        return formattedName;
    },

    /**
     * formats user's first name, middle name and last name
     * @param data
     * @returns {string}
     */
    formatFirstMiddleLastName: function (data) {
        var formattedName = '';

        // no data found
        if (general.isEmpty(data)) {
            return formattedName;
        }

        // first name
        if (general.notEmpty(data.first_name)) {
            formattedName = data.first_name;
        }
        // middle name
        if (general.notEmpty(data.middle_name)) {
            formattedName = general.isEmpty(formattedName) ? data.middle_name : formattedName + ' ' + data.middle_name;
        }
        // last name
        if (general.notEmpty(data.last_name)) {
            formattedName = general.isEmpty(formattedName) ? data.last_name : formattedName + ' ' + data.last_name;
        }
        formattedName = general.ucFirstAllWords(formattedName);
        return formattedName;
    }

};

export default userHelper;