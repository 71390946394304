import * as general from './general';

/**
 * check if a task is related to bank transaction verification
 * @param constants
 * @param task
 * @returns {boolean}
 */
export function isBankTransaction (constants, task) {
    // check that constants and task object is not empty
    if (general.notEmpty(constants) &&
        general.notEmpty(constants.TASK_TYPES) &&
        general.notEmpty(task) &&
        general.notEmpty(task.task_fk_id) &&
        general.notEmpty(task.task_type)) {
        // check that task type matches bank transaction verification related task types
        if (general.cmpStr(constants.TASK_TYPES.VERIFICATION_NATURE, task.task_type) ||
            general.cmpStr(constants.TASK_TYPES.VERIFICATION_DOC, task.task_type)) {
            return true;
        }
        return false;
    }
    return false;
}