import { getEnv } from '../helpers/general.js';

export const config = {

    "development": {
        "env": "development",
        "appName": "Lanturn",
        "webUrl": "http://accounts-ninja-web:1337",
        "apiUrl": "https://localhost:3001",
        "apiAnalytics": {"url": "http://localhost:3002", "logData": false},
        "webappUrl": "https://localhost:4001",
        "webappAdminUrl": "https://localhost:5001",
        "webappSpUrl": "https://localhost:6001",
        "cdnUrl": ["https://cdn.nerdyhawk.com"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LepfDAUAAAAACLoKdB4lClIVJrdnlvClPDmHG4m"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-ftcn543q73spsmkg5hobi1bkkdcrj4pg.apps.googleusercontent.com",
            "clientSecret": "dfMTAa5ny6CoIjwEch7eC0Gk",
            "callbackUrl": "https://app.getzave.com/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_test_SBN1NdyyrMdso3uHFXI9liAd00OwsTh5in"
        },
        "intercom": {
            "appId": "qo80jnlo"
        },
        "pusher": {
            "api_key": "929bd06df3c696beb87d",
            "cluster": 'ap1',
            "authEndpoint": "https://afee6160.ap.ngrok.io/v1.0.0/webhook/push-notification/pusher/auth"
        },
        "nowinfinity": {
            "clientId": 'getzave_dev',
            "authEndpointUrl": 'https://auth-dublin.leo.nowinfinity.com.au',
            "authHostName": 'auth-dublin.leo.nowinfinity.com.au',
            "apiEndpointUrl": 'https://api-dublin.leo.nowinfinity.com.au',
            "apiHostName": 'api-dublin.leo.nowinfinity.com.au',
            "redirectUri": 'https://localhost:6001/auth/nowinfinity/callback',
            "scope": "api1 offline_access"
        }
    },

    "staging": {
        "env": "staging",
        "appName": "Lanturn",
        "webUrl": "https://nerdyhawk.com",
        "apiUrl": "https://api.nerdyhawk.com",
        "apiAnalytics": {"url": "https://api-analytics.nerdyhawk.com", "logData": true},
        "webappUrl": "https://app.nerdyhawk.com",
        "webappAdminUrl": "https://staff.nerdyhawk.com",
        "webappSpUrl": "https://partner.nerdyhawk.com",
        "cdnUrl": ["https://cdn.nerdyhawk.com"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LepfDAUAAAAACLoKdB4lClIVJrdnlvClPDmHG4m"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-t0r6lgt5c1ddrk1uoffb188i52307s5n.apps.googleusercontent.com",
            "clientSecret": "4HSYbJbWZS8aHwiLSovkXLVz",
            "callbackUrl": "https://app.nerdyhawk.com/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_test_SBN1NdyyrMdso3uHFXI9liAd00OwsTh5in"
        },
        "intercom": {
            "appId": "qo80jnlo"
        },
        "pusher": {
            "api_key": "929bd06df3c696beb87d",
            "cluster": 'ap1',
            "authEndpoint": "https://api-oscar.nerdyhawk.com/v1.0.0/webhook/push-notification/pusher/auth"
        },
        "nowinfinity": {
            "clientId": 'getzave_dev',
            "authEndpointUrl": 'https://auth-dublin.leo.nowinfinity.com.au',
            "authHostName": 'auth-dublin.leo.nowinfinity.com.au',
            "apiEndpointUrl": 'https://api-dublin.leo.nowinfinity.com.au',
            "apiHostName": 'api-dublin.leo.nowinfinity.com.au',
            "redirectUri": 'https://partner.nerdyhawk.com/auth/nowinfinity/callback',
            "scope": "api1 offline_access"
        }
    },

    "qa": {
        "env": "qa",
        "appName": "Lanturn",
        "webUrl": "https://primaryone.net",
        "apiUrl": "https://api.primaryone.net",
        "apiAnalytics": {"url": "https://api-analytics.primaryone.net", "logData": true},
        "webappUrl": "https://app.primaryone.net",
        "webappAdminUrl": "https://staff.primaryone.net",
        "webappSpUrl": "https://partner.primaryone.net",
        "cdnUrl": ["https://cdn.primaryone.net"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LepfDAUAAAAACLoKdB4lClIVJrdnlvClPDmHG4m"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-rlstlqiahjfqqp6eduovsva9rjo9o7br.apps.googleusercontent.com",
            "clientSecret": "9KPmFYfgllSM4PQwxWRbUO8z",
            "callbackUrl": "https://app.primaryone.net/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_test_SBN1NdyyrMdso3uHFXI9liAd00OwsTh5in"
        },
        "intercom": {
            "appId": "qo80jnlo"
        },
        "pusher": {
            "api_key": "929bd06df3c696beb87d",
            "cluster": 'ap1',
            "authEndpoint": "https://api-oscar.primaryone.net/v1.0.0/webhook/push-notification/pusher/auth"
        },
        "nowinfinity": {
            "clientId": 'GetZave',
            "authEndpointUrl": 'https://auth.nowinfinity.com.au',
            "authHostName": 'auth.nowinfinity.com.au',
            "apiEndpointUrl": 'https://api.nowinfinity.com.au',
            "apiHostName": 'api.nowinfinity.com.au',
            "redirectUri": 'https://partner.primaryone.net/auth/nowinfinity/callback',
            "scope": "api1 offline_access"
        }
    },

    "production": {
        "env": "production",
        "appName": "Lanturn",
        "webUrl": "https://www.getzave.com",
        "apiUrl": "https://api.getzave.com",
        "apiAnalytics": {"url": "https://api-analytics.getzave.com", "logData": true},
        "webappUrl": "https://app.getzave.com",
        "webappAdminUrl": "https://staff.getzave.com",
        "webappSpUrl": "https://partner.getzave.com",
        "cdnUrl": ["https://cdn.getzave.com"],
        "recaptcha": {
            "signUp": {
                "clientKey": "6LcdFTIUAAAAACGh4A6MyoDwJYRnQBLSBIxbTa-4"
            }
        },
        "googleMaps": {
            "apiKey": "AIzaSyCXu3qd7pPOzt5x1A0memgvdDlv-7dv0xE"
        },
        "googleAuth": {
            "clientId": "652886084704-f655u9dh95jamvtpt4ce4v91pa5pap89.apps.googleusercontent.com",
            "clientSecret": "uYjfwCitf1LuGgB9meTiwomy",
            "callbackUrl": "https://app.getzave.com/signin/google/callback"
        },
        "stripe": {
            "publishableKey": "pk_live_VA7NY6tOpbOVt7wSIJhvkaM100nXRDi1vs"
        },
        "intercom": {
            "appId": "qo80jnlo"
        },
        "pusher": {
            "api_key": "82ebb4154af7e101f5bd",
            "cluster": 'ap1',
            "authEndpoint": "https://api-oscar.getzave.com/v1.0.0/webhook/push-notification/pusher/auth"
        },
        "nowinfinity": {
            "clientId": 'GetZave',
            "authEndpointUrl": 'https://auth.nowinfinity.com.au',
            "authHostName": 'auth.nowinfinity.com.au',
            "apiEndpointUrl": 'https://api.nowinfinity.com.au',
            "apiHostName": 'api.nowinfinity.com.au',
            "redirectUri": 'https://partner.getzave.com/auth/nowinfinity/callback',
            "scope": "api1 offline_access"
        }
    }

}[getEnv()];
