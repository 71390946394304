let DocumentHelper = module.exports;

/**
 * "replace_clauses": [
 {
     "heading": "replacement clause",
     "heading_key": "header1",
     "oldprefix": [
       "1.a",
       "3.c"
     ],
     "paras": [
       {
         "style": "para1",
         "text": "Replacement Clause 1"
       }
     ]
   }
 ],
 "insert_clauses": [
 {
     "heading": "sample header",
     "heading_key": "header1",
     "paras": [
       {
         "style": "para1",
         "text": "122. sample text for new paragraph 1"
       },
       {
         "style": "para1",
         "text": "123. sample text for new paragraph 2"
       }
     ]
   }
 ]
 */

const CLAUSE_TYPE = {
    REPLACE_CLAUSES: "replace_clauses",
    INSERT_CLAUSES: "insert_clauses"
};

/**
 * Translate client-side this.state.fields into document generation required JSON schema format
 * @param {object} fields
 * fields = {
 *   replacementClause_header1: {value: "header value"},
 *   replacementClause_header1_para1: {value: "some value"},
 *   replacementClause_header2_para1: {value: "some value"},
 *   replacementClause_header3_para1: {value: "some value"},
 *   replacementClause_header4_para1: {value: "some value"},
 *   replacementClause_header5_para1: {value: "some value"},
 *   insertionClause_header1_para1: {value: "some insertion clause"},
 *   insertionClause_header1_para2: {value: "some insertion clause"},
 *   insertionClause_header2_para1: {value: "some insertion clause"}
 * }
 */
module.exports.processClauseFields = (fields) => {
    let clausesSchema = {
        replace_clauses: [],
        insert_clauses: []
    };
    for (let i = 0; i < Object.keys(fields).length; i++) {
        const fieldKey = Object.keys(fields)[i];
        let fieldKeyParts = fieldKey.split("_");
        //Paragraph value
        if (fieldKeyParts.length > 2) {
            let headingKey = fieldKeyParts[1];
            let paraValue = fields[fieldKey].value;
            if (fieldKey.indexOf('replacementClause') > -1) {
                clausesSchema = DocumentHelper.updateClausePara(CLAUSE_TYPE.REPLACE_CLAUSES, clausesSchema, headingKey, paraValue);
            }
            if (fieldKey.indexOf('insertionClause') > -1) {
                clausesSchema = DocumentHelper.updateClausePara(CLAUSE_TYPE.INSERT_CLAUSES, clausesSchema, headingKey, paraValue);
            }
        }
        //Header value
        else if (fieldKeyParts.length === 2) {
            let headingKey = fieldKeyParts[1];
            let headingValue = fields[fieldKey].value;
            if (fieldKey.indexOf('replacementClause') > -1) {
                clausesSchema = DocumentHelper.updateClauseHeader(CLAUSE_TYPE.REPLACE_CLAUSES, clausesSchema, headingKey, headingValue);
            }
            if (fieldKey.indexOf('insertionClause') > -1) {
                clausesSchema = DocumentHelper.updateClauseHeader(CLAUSE_TYPE.INSERT_CLAUSES, clausesSchema, headingKey, headingValue);
            }
        }
    }
    return clausesSchema;
};

/**
 * Update clause para value
 * @param {string} clauseType
 * @param {object} clausesSchema
 * @param {string} headerKeyToFind
 * @param {string} paraValue
 * @returns {*}
 */
module.exports.updateClausePara = (clauseType, clausesSchema, headerKeyToFind, paraValue) => {
    //Initialise new clause if clause does not exist yet
    if (!DocumentHelper.findClauseByHeadingKey(clauseType, clausesSchema, headerKeyToFind)) {
        let newClause = DocumentHelper.initNewClause(clauseType);
        newClause.heading_key = headerKeyToFind;
        clausesSchema[clauseType].push(newClause);
    }

    //Iterate through clause schema and push new para value into this clause header
    for (let i = 0; i < clausesSchema[clauseType].length; i++) {
        if (clausesSchema[clauseType][i].heading_key === headerKeyToFind) {
            let paraObj = {
                style: 'para1',
                text: paraValue
            }
            clausesSchema[clauseType][i].paras.push(paraObj);
        }
    }

    return clausesSchema;
};

/**
 * Update clause heading value
 * @param {string} clauseType
 * @param {object} clausesSchema
 * @param {string} headerKeyToFind
 * @param {string} headingValue
 * @returns {*}
 */
module.exports.updateClauseHeader = (clauseType, clausesSchema, headerKeyToFind, headingValue) => {
    //Initialise new clause if clause does not exist yet
    if (!DocumentHelper.findClauseByHeadingKey(clauseType, clausesSchema, headerKeyToFind)) {
        let newClause = DocumentHelper.initNewClause(clauseType);
        newClause.heading_key = headerKeyToFind;
        clausesSchema[clauseType].push(newClause);
    }

    //Iterate through clause schema and push new para value into this clause header
    for (let i = 0; i < clausesSchema[clauseType].length; i++) {
        if (clausesSchema[clauseType][i].heading_key === headerKeyToFind) {
            clausesSchema[clauseType][i].heading = headingValue;
        }
    }

    return clausesSchema;
};

/**
 * Find clause by heading_key E.g. header1, header2
 * @param {string} clauseType
 * @param {object} clausesSchema
 * @param {string} headerKeyToFind
 * @returns {boolean}
 */
module.exports.findClauseByHeadingKey = (clauseType, clausesSchema, headerKeyToFind) => {
    let headerKeyExists = false;
    //Check if clause already exists or not by header
    for (let i = 0; i < clausesSchema[clauseType].length; i++) {
        if (clausesSchema[clauseType][i].heading_key === headerKeyToFind) {
            headerKeyExists = true;
        }
    }
    return headerKeyExists;
};

/**
 * Scaffold new clause object
 * @param {string} clauseType
 * @returns {{heading_key: string, heading: string, oldprefix: [string, string], paras: []}|{heading_key: string, heading: string, paras: []}}
 */
module.exports.initNewClause = (clauseType) => {
    switch (clauseType) {
        case CLAUSE_TYPE.REPLACE_CLAUSES:
            return {
                heading: "",
                heading_key: "",
                oldprefix: ["1.a", "3.c"],
                paras: []
            };
        case CLAUSE_TYPE.INSERT_CLAUSES:
            return {
                heading: "",
                heading_key: "",
                paras: []
            };
    }
};
