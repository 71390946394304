import { h } from './index';

var tableHelper = {

    /**
     * hide column headers by column names provided in columnsToHide array
     * @param state
     * @param rowInfo
     * @param column
     * @param columnsToHide
     * @returns {*}
     */
    hideColumnHeaders: function (state, rowInfo, column, columnsToHide) {
        if (h.notEmpty(columnsToHide)) {
            for (var i = 0; i < columnsToHide.length; i++) {
                if (h.cmpStr(column.Header, columnsToHide[i])) {
                    return { style: { display: 'none' } };
                }
            }
        }
        return {};
    }

};

export default tableHelper;