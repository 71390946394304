export default function expense (state = {}, action) {
    switch (action.type) {
        case 'SET_CURRENT_CLAIMS':
            return {
                ...state,
                currentClaims: action.currentClaims.data,
                currentClaimsGroup: action.currentClaims.group,
                currentClaimsSummary: action.currentClaims.summary
            };
        case 'SET_SUBMITTED_CLAIMS':
            return {
                ...state,
                submittedClaims: action.submittedClaims.data,
                submittedClaimsGroup: action.submittedClaims.group,
                submittedClaimsSummary: action.submittedClaims.summary
            };
        case 'SET_AUTHORISED_CLAIMS':
            return {
                ...state,
                authorisedClaims: action.authorisedClaims.data,
                authorisedClaimsGroup: action.authorisedClaims.group,
                authorisedClaimsSummary: action.authorisedClaims.summary
            };
        case 'SET_PREVIOUS_CLAIMS':
            return {
                ...state,
                previousClaims: action.previousClaims.data,
                previousClaimsGroup: action.previousClaims.group,
                previousClaimsSummary: action.previousClaims.summary
            };
        default:
            return state;
    }
}