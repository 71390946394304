import $ from 'jquery';
import { config } from '../configs/config';

/**
 * create basic user api
 * @param data
 * @param callback
 */
// export function createUserV2 (data, callback) {
//     $.ajax({
//         method: "POST",
//         url: config.apiUrl + '/v1.0.0/user/create-user',
//         dataType: "json",
//         data: data,
//         error: callback
//     }).done(callback);
// }

/**
 * create user api
 * @param data
 * @param callback
 */
export function createUser (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/signup',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * generate temporary zave portal login
 * @param data
 * @param callback
 */
export function generateTemporaryLogin(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/generate-temporary-login',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * generate temporary user access token
 * @param data
 * @param callback
 */
export function generateTemporaryAccessToken(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/generate-temporary-access-token',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * create user by invitation api
 * @param data
 * @param callback
 */
export function createUserByInvite (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/invite-signup',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check email address availability
 * @param data
 * @param callback
 */
export function checkEmailAvailability (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/check-email-availability',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check if email exist in system
 * @param data
 * @param callback
 */
export function checkEmailExist(data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/check-email-exist',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * verify recaptcha
 * @param data
 * @param callback
 */
export function verifyRecaptcha (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/verify-recaptcha',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * verify email
 * @param data
 * @param callback
 */
export function verifyEmail (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/verify-email',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * resend verification email
 * @param data
 * @param callback
 */
export function resendVerificationEmail (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/resend-verification-email',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * email login
 * @param data
 * @param callback
 */
export function login (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/login',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * email login
 * @param data
 * @param callback
 */
export function loginGoogle (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/login/google',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * user sign out
 * @param data
 * @param callback
 */
export function signout (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/signout',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check access token validity
 * @param callback
 */
export function checkToken (callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/check-token',
        dataType: "json",
        data: {},
        error: callback
    }).done(callback);
}

/**
 * user forgot password
 * @param data
 * @param callback
 */
export function forgotPassword (data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/sp-forgot-password',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * user reset password
 * @param data
 * @param callback
 */
export function resetPassword (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/sp-reset-password',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get account creation invitation
 * @param data
 * @param callback
 */
export function getInvite (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/get-invite',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get user
 * @param data
 * @param callback
 */
export function getUser (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/user/get-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get user
 * @param data
 * @param callback
 */
export function updateUser (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/user/update-user',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get user
 * @param data
 * @param callback
 */
export function getUserByEmail (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/email',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * update user role
 * @param data
 * @param callback
 */
export function updateUserRole (data, callback) {
    $.ajax({
        method: "PUT",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/user-role',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * Get entities visible for a user (with server-side pagination support)
 * @param {*} data
 * @param {*} callback
 */
export function getServiceProviderVisibleEntities (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/get-serviceprovider-visible-entities',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * Get entities visible for a user (without server-side pagination support)
 * @param {*} data
 * @param {*} callback
 */
export function getServiceProviderVisibleEntitiesAll (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/get-serviceprovider-visible-entities-all',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * Get entities with no services for a service provider
 * @param {*} data
 * @param {*} callback
 */
export function getServiceProviderUntaggedEntities (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/user/get-serviceprovider-untagged-entities',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check if the password reset token is valid
 * @param {{ token : string }} data
 * @param {*} callback
 */
export function checkResetPasswordToken(data, callback) {
    $.ajax({
        method: "POST",
        url: config.apiUrl + '/v1.0.0/user/check-reset-password-token',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}
