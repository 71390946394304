
export const format = {
    formatMemberIdentityForAutoComplete(member_identity) {
        let identity = {};
        switch (member_identity.member_identity_type) {
            case 'individual': {
                let { first_name, middle_name, last_name, email } = member_identity;
                let name_tokens = [first_name, middle_name, last_name].filter(com => com); //Ensure that only non-empty tokens are included
                if (email) {
                    identity = {
                        text: `${name_tokens.join(' ')} (${email})`,
                        value: member_identity.member_identity_id
                    };
                } else {
                    identity = {
                        text: `${name_tokens.join(' ')}`,
                        value: member_identity.member_identity_id
                    };
                }
                break;
            }
            case 'corporate': {
                let { company_name, auth_rep_first_name, auth_rep_last_name, auth_rep_email } = member_identity;
                if (auth_rep_first_name && auth_rep_last_name && auth_rep_email) {
                    identity = {
                        text: `${company_name || ''} (${auth_rep_first_name || ''} ${auth_rep_last_name || ''} - ${auth_rep_email})`,
                        value: member_identity.member_identity_id
                    };
                } else {
                    identity = {
                        text: `${company_name || ''}`,
                        value: member_identity.member_identity_id
                    };
                }
                break;
            }
            default: {
                identity = {
                    text: '',
                    value: ''
                };
                break;
            }
        }
        return identity;
    }
};
export default {
    format
};