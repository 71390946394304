import $ from 'jquery';
import { config } from '../configs/config';

/**
 * gets system configuration
 * @param data
 * @param callback
 */
export function getConfig (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-config',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * gets system constants
 * @param data
 * @param callback
 */
export function getConstant (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-constant',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get address using postal code
 * @param data
 * @param callback
 */
export function getAddress (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-address',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get list of countries
 * @param data
 * @param callback
 */
export function getCountries (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-countries',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get list of currencies
 * @param data
 * @param {*} callback
 */
export function getCurrencies (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-currencies',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * get list of currencies
 * @param data
 * @param {*} callback
 */
export function getSSICCodesSG (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/get-ssic-codes-sg',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * check email
 * @param data
 * @param callback
 */
export function checkEmail (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/general/check-email',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * Generate email signature
 * @param data
 * @param callback
 */
export function generateEmailSignature (data, callback) {
    $.ajax({
        method: "GET",
        url: config.apiUrl + '/v1.0.0/serviceProvider/general/email/signature/generate',
        dataType: "json",
        data: data,
        error: callback
    }).done(callback);
}

/**
 * compare two value, will return 1, -1, 0 if greater, leaser, and equal respectifely
 *
 * @param type integer, string, float, boolean
 * @param a
 * @param b
 * @returns {number}
 */
export function compareTwoValue (type, a, b) {
    switch (type) {
        case 'float':
            if (typeof a === 'string') {
                a = a.replace(',', '') || 0;
                b = b.replace(',', '') || 0;
            }
            a = parseFloat(a) || 0;
            b = parseFloat(b) || 0;
            break;

        case 'integer':
        default:
            a = parseInt(a, 10) || 0;
            b = parseInt(b, 10) || 0;
            break;

        case 'string':
            // force null and undefined to the bottom
            a = a === null || a === undefined ? -Infinity : a;
            b = b === null || b === undefined ? -Infinity : b;
            // force any string values to lowercase
            a = typeof a === "string" ? a.toLowerCase() : a;
            b = typeof b === "string" ? b.toLowerCase() : b;
            break;
    }
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    } else {
        return 0;
    }
}

//==================
// TYPE DEFINITIONS
//==================
/**
 * @typedef Currency
 * @property {string} [currency_id]
 * @property {string} [currency_code]
 * @property {string} [name]
 * @property {string} [name_plural]
 * @property {string} [symbol]
 * @property {string} [symbol_native]
 * @property {*} [decimal_digits]
 * @property {*} [rounding]
 * @property {string} [created_by]
 * @property {*} [created_date]
 * @property {string} [updated_by]
 * @property {*} [updated_date]
 */
