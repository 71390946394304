import * as UserModel from '../models/user';
import jwt from 'jsonwebtoken';
import { getEnv, findGetParameter, compareBoolean } from '../helpers/general.js';

export const cookiePrefix = 'A1CI5NHUCQPX72N9LJW2_' + getEnv() + '_ANWebApp_';
export const cookieAccessToken = 'AlphaTango';
export const cookieRememberMe = 'RomeoMight';
export const cookieEmail = 'Echo';
export const cookieTourExpenses = 'TangoEcho';
export const cookieCurrentEntityId = 'CharlieEchoIndia';
export const cookieCurrentServiceProviderId = 'CharlieSierraPapaIndia';
export const cookieIncorporationFormId = 'IndiaFoxtrotIndia';

/**
 * checks if user is authenticated by checking for access token in cookie
 * @returns {boolean}
 */
export function isAuthenticated () {
    var accessToken = this.getAccessToken();
    if (accessToken && accessToken !== '') {
        return true;
    } else {
        return false;
    }
}

/**
 * sign out function
 * @param callback
 */
export function signOut (callback) {
    var data = { device_type: 'web' };
    UserModel.signout(data, callback);
}

/**
 * get all cookies and set into an array
 * @returns {{}}
 */
export function getCookies () {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i=0; i<pairs.length; i++){
        var pair = pairs[i].split("=");
        cookies[pair[0].trim()] = unescape(pair[1]);
    }
    return cookies;
}

/**
 * store a cookie using key and value
 * @param key
 * @param value
 */
export function setCookie (key, value) {
    this.deleteCookie(key);
    document.cookie = this.cookiePrefix + key + '='+ value +'; path=/';
}

/**
 * get cookie value by key
 * @param key
 * @returns {string}
 */
export function getCookie (key) {
    var cookies = this.getCookies(),
        cookieKey = this.cookiePrefix + key,
        accessToken = cookies[cookieKey] ? cookies[cookieKey] : '';
    return accessToken;
}

/**
 * delete a cookie by key
 * @param key
 */
export function deleteCookie (key) {
    document.cookie = this.cookiePrefix + key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
}

/**
 * store access token
 * @param accessToken
 */
export function setAccessToken (accessToken) {
    this.deleteAccessToken();
    this.setCookie(this.cookieAccessToken, accessToken);
}

/**
 * delete access token
 */
export function deleteAccessToken () {
    this.deleteCookie(this.cookieAccessToken);
}

/**
 * get access token
 * @returns {string}
 */
export function getAccessToken () {
    return this.getCookie(this.cookieAccessToken);
}

/**
 * get user information from jwt
 * @returns {{}}
 */
export function getUserInfo () {
    var decoded = jwt.decode(this.getAccessToken());
    return decoded && decoded.atd && decoded.atd.user ? decoded.atd.user : {};
}

/**
 * redirect user with non-authenticated session to login page
 * @param {string} [redirectDomain] Domain to overwrite default redirect behaviour
 * @param {string} [customMessage] Custom message to show user when redirected to login page
 * @param {boolean} [redirectOnLogin=true] Flag to indicate whether to redirect to previous URL after successful login
 */
export function redirectToLogin (redirectDomain, customMessage, redirectOnLogin) {
    redirectOnLogin = compareBoolean(redirectOnLogin, false) ? false : true;
    let noRedirectFlag = findGetParameter('no_redirect');
    if (noRedirectFlag && noRedirectFlag == '1') return;
    let redirectRoute = findGetParameter('redirectRoute');
    let currentPathnameEncoded = encodeURIComponent(window.location.pathname);
    let currentSearchEncoded = encodeURIComponent(window.location.search);
    let redirectTo = `${redirectDomain || ''}/login?`;
    if (redirectOnLogin && !redirectRoute) redirectTo = `${redirectTo}&redirect_route=${currentPathnameEncoded}&redirect_search=${currentSearchEncoded}&`;
    if (customMessage) redirectTo = `${redirectTo}error_message=${encodeURIComponent(customMessage)}&`;
    redirectTo = `${redirectTo}no_redirect=${encodeURIComponent(redirectOnLogin ? 0 : 1)}&`;
    window.location.href = redirectTo;
}